export interface PunishmentNotificationDTO {
    id: string
    type: PunishmentNotificationType
    reason: PunishmentReason
    reasonMessage: string
    player: any
    playerA: any
    playerB: string
    tourMatchId: string
    tournamentId: string
    creationTime: string
}


export enum PunishmentNotificationType {
    YELLOW_CARD = 'YELLOW_CARD',
    RED_CARD = 'RED_CARD',
}

export enum PunishmentReason {
    ONE_DID_NOT_PRESS_START = 'ONE_DID_NOT_PRESS_START',
    ONE_DID_NOT_PRESS_FINISH = 'ONE_DID_NOT_PRESS_FINISH',
    ONE_DID_NOT_ENTER_SCORE = 'ONE_DID_NOT_ENTER_SCORE',
    BOTH_DID_NOT_PRESS_START = 'BOTH_DID_NOT_PRESS_START',
    BOTH_DID_NOT_ENTER_SCORE = 'BOTH_DID_NOT_ENTER_SCORE',
}
