import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { AngularFireDatabase } from '@angular/fire/database'
import { imageAvatarFallback, imageFallback } from '../../../utils/string.util'
import { SupportMessageItem } from '../../models/SupportMessageItem'

@Component({
  selector: 'app-chat-message-item',
  templateUrl: './chat-message-item.component.html',
  styleUrls: ['./chat-message-item.component.scss'],
})
export class ChatMessageItemComponent implements OnInit, OnDestroy {

  constructor(
    private db: AngularFireDatabase,
  ) { }

  @Input()
  message: SupportMessageItem
  @Input()
  moderatorId: string
  @Input()
  isReport = false

  @Input()
  isP2P = false

  readByMe( ): boolean {
    const readHistory = Object.keys(this.message.readBy || {})
    return readHistory.indexOf( this.moderatorId ) > -1
  }

  ngOnInit() {

    // if (!this.readByMe() ) {

      // if (!this.message.userExtraId) {
      //   return
      // }

      // if (!this.isReport) {
      //   return
      // }

      // console.log('Updating message ', this.message, 'with readBy')
      // this.db.object(
      // `tournaments_extra/${this.message.tournamentId}/support_messages/${this.message.userExtraId}/${this.message.id}/readBy/`,
      // ).update({
      //     [this.moderatorId]: true,
      // })
    // }

  }

  avatarLinkHandler( url ) {
    return imageAvatarFallback(url)
  }

  imageLinkHandler( url ) {
    return imageFallback( url )
  }

  ngOnDestroy() {

  }
}

