import { Component, OnInit } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { ActivatedRoute, Params, Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { AppState } from '../../../reducers/index'
import { Faq } from '../../models/faq.model'
import { FaqHttpService } from '../../services/faq-http.service'
import { createFaq, editFaq } from '../../store/faq-feature.actions'

@Component({
  selector: 'app-faq-create',
  templateUrl: './faq-create.component.html',
  styleUrls: ['./faq-create.component.scss'],
})
export class FaqCreateComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<AppState>,
    private router: Router,
    private route: ActivatedRoute,
    private faqHttp: FaqHttpService,
  ) { }

  faqForm
  faqToEdit
  faqId

  isEditScreen: boolean = undefined

  ngOnInit() {

    this.route.params.subscribe( (params: Params) => {
      if (params) {
        const { id } = params
        if (id ) {

          this.faqHttp.fetchById( id ).subscribe(
            (value: Faq ) => {
              this.faqToEdit = value
              const { ask, answer, tag } = value
              this.initForm(ask, answer, tag)
            },
            )
          this.isEditScreen = true
          this.faqId = id
          } else {
            this.isEditScreen = false
          }
        }
      })
    this.initForm()
  }

  isInitialized(): boolean {
    return typeof this.isEditScreen === 'boolean'
  }

  initForm( ask = '', answer = '', tag = '') {

    this.faqForm = this.formBuilder.group({
      ask: [ask, [Validators.required, Validators.minLength(5)]],
      answer: [answer, [Validators.required, Validators.minLength(5), Validators.maxLength(499)]],
      tag: [ tag ],
    })

  }

  save() {

    const { ask, answer, tag } = this.faqForm.value

    !this.faqId ?
    this.store.dispatch(createFaq(
      { faq: { ask, answer, tag } },
      )) :
      this.store.dispatch(editFaq(
        { faq: { ask, answer, tag, id: this.faqId } },
    ))

    this.router.navigate(['/cms-nav/faq'])
  }

  cancel() {
    this.router.navigate(['/cms-nav/faq'])
  }




}
