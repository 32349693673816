
/*
  Example event:
  {
	"tourMatchId" : 14152,
	"event" : {
	  "id" : 47,
	  "node" : "A",
	  "type" : "YELLOW_CARD",
	  "tournamentId" : 686,
	  "tourMatchId" : 14152,
	  "time" : "2019-11-21T12:26:14.128Z",
	  "data" : {
		"reason" : "765476"
	  }
	}
*/

export interface TournamentEvent {
     tourMatchId: string;
     event: {
          id: string,
          node: string,
          type: MatchEventType,
          tournamentId: string,
          tourMatchId: string,
          time: string,
          data?: {
                    reason?: string,
                    enteredScoreA?: string,
                    enteredScoreB?: string,
               }
     };
}

export enum MatchEventType  {
    YELLOW_CARD = 'YELLOW_CARD',
    RED_CARD = 'RED_CARD',
    CLICK_READY  = 'CLICK_READY',
    CLICK_START = 'CLICK_START',
    CLICK_FINISH = 'CLICK_FINISH',
    ENTER_SCORE = 'ENTER_SCORE',
    GIVE_UP =  'GIVE_UP',
    MODERATOR_DECIDE_WINNER = 'MODERATOR_DECIDE_WINNER',
    CANCEL_MATCH = 'CANCEL_MATCH',
    RESET_MATCH = 'RESET_MATCH'
}
