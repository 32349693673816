
export const ARRAY_STATUS = [
    {
      value: 'LOCK',
      label: 'Locked',
      code: true,
      isChecked : true,
    },
    {
      value: 'UNLOCK',
      label: 'Unlocked',
      code: false,
      isChecked: false,
    },
   ]
export const ARRAY_PRICE = [
    {
        label: 'Free',
        value: 'free',
        isChecked : false,
    },
    {
         label: 'For Coins',
         value: 'price_free',
         isChecked : true,
      },
 ]
export const DATE_FORMAT = 'YYYY-MM-DD'
export const DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm'
