import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { take } from 'rxjs/operators'
import { PushToAllModalComponent } from '../shared/components/push-to-all-modal/push-to-all-modal.component'
import { TimelineService } from '../timeline/timeline.service'

@Injectable({
  providedIn: 'root',
})
export class PushNotifierService {

  constructor(
    private dialog: MatDialog,
    private timelineService: TimelineService,


  ) { }

  notifyPersonally({user: { firstName, id}, moderatorId}) {

    const dialog = this.dialog.open(
      PushToAllModalComponent,
      {
        backdropClass: 'modal-blurred', panelClass: 'modal-no-padding',
        data: { title: `Notify ${firstName || 'player'}` },
      },
    )

    dialog.afterClosed().subscribe(data => {
      if (data) {
        const { title, message } = data

        const msg = {
          moderatorId,
          title,
          message,
          useForAll: false,
          tournamentId: null,
          userIds: [id],
        }

        this.timelineService.notifyAllUsers(msg).pipe(take(1))
        .subscribe(res => console.log(res) )
      }
    })
  }
}
