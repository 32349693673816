import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { MatCheckboxModule } from '@angular/material'
import { ImageLoaderModule } from '../../../shared/components/image-loader/image-loader.module'
import { AddQuestionModalComponent } from './add-question-modal.component'



@NgModule({
  declarations: [
    AddQuestionModalComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ImageLoaderModule,
    MatCheckboxModule,
  ],
  entryComponents: [
    AddQuestionModalComponent,
  ],
  exports: [
    AddQuestionModalComponent,
  ],

})
export class AddQuestionModalModule { }
