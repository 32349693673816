import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core'
import { Store } from '@ngrx/store'
import { Subject, Observable } from 'rxjs';
import { AppConstants } from 'src/app/app.constants'
import { AppState } from 'src/app/reducers'
import { selectUserId } from '../../../auth/account.selectors';

@Component({
  selector: 'app-moderators-chat-message-item',
  templateUrl: './moderators-chat-message-item.component.html',
  styleUrls: ['./moderators-chat-message-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModeratorsChatMessageItemComponent {

  @Input()
  message

  @Input()
  userId: string

  compareId(id1, id2) {
    return id1.toString() === id2.toString()
  }

  imageLinkHandler(url: string | undefined): string {
    if ( !url ) { return 'assets/images/no-ava.png' }
    return url.startsWith('http') ? url : AppConstants.SERVER_API_URL + url
  }

}
