import { createAction, props } from '@ngrx/store'
import { PaginatorState } from '../../shared/model/paginatorState.model'
import { Faq } from '../models/faq.model'

export const loadFaqs = createAction(
  '[FaqFeature] Load FAQs',
)

export const loadFaqsSuccess = createAction(
  '[FaqFeature] Load FAQs Success',
  props<{ data: any, faqListLength: number }>(),
)

export const loadFaqsFailure = createAction(
  '[FaqFeature] Load FAQs Failure',
  props<{ error: any }>(),
)


export const createFaq = createAction(
  '[FaqFeature] Create FAQ',
  props<{ faq: Faq }>(),
)

export const createFaqSuccess = createAction(
  '[FaqFeature] Create FAQ Success',
  props<{ data: any }>(),
)

export const createFaqFailure = createAction(
  '[FaqFeature] Create FAQ Failure',
  props<{ error: any }>(),
)

export const editFaq = createAction(
  '[FaqFeature] Edit FAQ',
  props<{ faq: Faq }>(),
)

export const editFaqSuccess = createAction(
  '[FaqFeature] Edit FAQ Success',
  props<{ data: any }>(),
)

export const editFaqFailure = createAction(
  '[FaqFeature] Edit FAQ Failure',
  props<{ error: any }>(),
)

export const deleteFaq = createAction(
  '[FaqFeature] Delete FAQ',
  props<{ faqId: string }>(),
)

export const deleteFaqSuccess = createAction(
  '[FaqFeature] Delete FAQ Success',
  props<{ data: any }>(),
)

export const deleteFaqFailure = createAction(
  '[FaqFeature] Delete FAQ Failure',
  props<{ error: any }>(),
)

export const setFaqPaginator = createAction(
  '[FaqFeature] Set FAQ Paginator',
  props<{ state: PaginatorState }>(),
)