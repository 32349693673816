import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { of } from 'rxjs'
import { catchError, concatMap, map, withLatestFrom } from 'rxjs/operators'
import { AppState } from '../../reducers/index'
import { NewsHttpService } from '../services/news-http.service'
import * as NewsFeatureActions from './news-feature.actions'
import { selectNewsPaginator, selectNewsListSearchBar } from './news-feature.selectors'

@Injectable()
export class NewsFeatureEffects {

  onFaqListChanged$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        NewsFeatureActions.createNewsSuccess,
        NewsFeatureActions.deleteNewsSuccess,
        // TODO: Hack because server returns error in case of good request handle
        NewsFeatureActions.deleteNewsFailure,
        NewsFeatureActions.editNewsSuccess,
      ),
      concatMap(faq => of(NewsFeatureActions.loadNews()),
      ))
  })


  searchStringChange = createEffect(() => {

    return this.actions$.pipe(

      ofType(
        NewsFeatureActions.setSearchString,
      ),
      concatMap(
        yep => of(NewsFeatureActions.loadNews()),
      ),
    )

  })


  loadNewsFeatures$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NewsFeatureActions.loadNews),
      withLatestFrom(
          this.store.select(selectNewsPaginator),
          this.store.select(selectNewsListSearchBar),
        ),

      concatMap(( [action, { page, size }, name]) =>
        this.newsHttp.fetchNews( page, size, name ).pipe(
          map( response => {

            const { body } = response
            const newsLength = + response.headers.get('X-Total-Count')

            return NewsFeatureActions.loadNewsSuccess({ data: body, newsLength })

          }),
          catchError(error => of(NewsFeatureActions.loadNewsFailure({ error })))),
      ),
    )
  })

  loadNewsById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NewsFeatureActions.getNewsById),

      concatMap(( { newsId }) =>
        this.newsHttp.getNewsById(newsId).pipe(
          map( data => NewsFeatureActions.getNewsByIdSuccess({ data }),
          ),
          catchError(error => of(NewsFeatureActions.getNewsByIdFailure({ error })))),
      ),
    )
  })


  createNewsEffectAPI$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NewsFeatureActions.createNews),
      concatMap(({ newsItem }) =>
        this.newsHttp.createNews(newsItem).pipe(
          map(data => NewsFeatureActions.createNewsSuccess({ data })),
          catchError(error => of(NewsFeatureActions.createNewsFailure({ error })))),
      ),
    )
  })

  editNewsEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NewsFeatureActions.editNews),
      concatMap(({ newsItem }) =>
        this.newsHttp.editNews({ newsItem }).pipe(
          map(data => NewsFeatureActions.editNewsSuccess({ data })),
          catchError(error => of(NewsFeatureActions.editNewsFailure({ error }))),
        ),
      ),
    )
  })

  deleteNewsEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NewsFeatureActions.deleteNews),
      concatMap(({ newsItemId }) =>
        this.newsHttp.deleteNews( newsItemId ).pipe(
          map(data => NewsFeatureActions.deleteNewsSuccess({ data }),
          ),
          catchError(error => of(NewsFeatureActions.deleteNewsFailure({ error }))),
        ),
      ),
    )
  })


  constructor(
    private actions$: Actions,
    private newsHttp: NewsHttpService,
    private store: Store<AppState>,
    ) {}

}
