import { AppConstants } from 'src/app/app.constants'

export default class StringUtils {
    static checkImageUrlContain(path: string): boolean {
        if (path) {
            if (path.indexOf('http') === 0) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    }
}

export const imageFallback = (url: string): string => {
    if (!url) { return; }
    return url.startsWith('http') ? url : AppConstants.SERVER_API_URL + url
}

export const imageAvatarFallback = (url: string | undefined): string  => {
    if (!url) {
        return 'assets/images/no-ava.png'
    }
    return url.startsWith('http') ? url : AppConstants.SERVER_API_URL + url
}
