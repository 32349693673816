import { Action, createReducer, on } from '@ngrx/store'
import * as SocketManagerActions from './socket-manager.actions'

export const socketManagerFeatureKey = 'socketManager'

export interface State {
  connected: boolean
  isConnecting: boolean
}

export const initialState: State = {
  connected: false,
  isConnecting: false,
}

const socketManagerReducer = createReducer(
  initialState,

  on(SocketManagerActions.connect, ( state ) => ({ ...state, isConnecting: true})),
  on(SocketManagerActions.connectSuccess, ( state ) => ({ ...state, connected: true, isConnecting: false })),

)

export function reducer(state: State | undefined, action: Action) {
  return socketManagerReducer(state, action)
}
