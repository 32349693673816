import { Component, OnDestroy, OnInit } from '@angular/core'
import { PageEvent } from '@angular/material/paginator'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { AppState } from '../../../reducers/index'
import { PaginatorState } from '../../../shared/model/paginatorState.model'
import { loadFaqs, setFaqPaginator } from '../../store/faq-feature.actions'
import { selectFaqCount, selectFaqList, selectFaqPaginator } from '../../store/faq-feature.selectors'

@Component({
  selector: 'app-faq-list',
  templateUrl: './faq-list.component.html',
  styleUrls: ['./faq-list.component.scss'],
})
export class FaqListComponent implements OnInit {

  faqItemsList$
  faqItemsListCount$

  faqPaginatorState: Observable<PaginatorState>

  ngOnInit() {
    this.store.dispatch( loadFaqs() )
    this.faqItemsList$ = this.store.select(selectFaqList)
    this.faqItemsListCount$ = this.store.select(selectFaqCount)
    this.faqPaginatorState = this.store.select(selectFaqPaginator)
  }

  onPaginatorChange(evt: PageEvent) {
    const { pageSize, pageIndex } = evt

    this.store.dispatch(
      setFaqPaginator({
        state: {
          size: pageSize,
          page: pageIndex,
        },
      }),
    )

    //TODO: move this refresh to effects
    this.refreshFaq()
  }

  refreshFaq() {
    this.store.dispatch( loadFaqs() )
  }

  constructor(
    private store: Store<AppState>,
  ) { }

}
