import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { EffectsModule } from '@ngrx/effects'
import { StoreModule } from '@ngrx/store'
import { AccountEffects } from './account.effects'
import * as fromAccountReducer from './account.reducer'


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromAccountReducer.accountFeatureKey, fromAccountReducer.reducer),
    EffectsModule.forFeature([AccountEffects]),
  ],
})
export class AuthModule { }
