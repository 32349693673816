import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { imageFallback } from '../../../utils/string.util'
import { NewsItem } from '../../models/news.model'
import { Store } from '@ngrx/store'
import { AppState } from 'src/app/reducers'
import { Router } from '@angular/router'
import { deleteNews } from '../../store/news-feature.actions'

@Component({
  selector: 'app-news-list-item',
  templateUrl: './news-list-item.component.html',
  styleUrls: ['./news-list-item.component.scss'],
})
export class NewsListItemComponent {

  @Input()
  newsItem: NewsItem

  @Input()
  index

  public even: any;


  constructor(
    private store: Store<AppState>,
    private router: Router,
  ) { }


  onNewsClick(evt) {
    const isDelete = evt.target.getAttribute('data-delete')
    isDelete
      ? this.onClickDelete()
      : this.onClickEdit()
  }

  onClickDelete() {
    this.store.dispatch(deleteNews({ newsItemId: this.newsItem.id }))
  }

  onClickEdit() {
    this.router.navigate(['/cms-nav/news', this.newsItem.id, 'edit'])
  }

  handleImg(url) {
    return imageFallback(url)
  }

}
