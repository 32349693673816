import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ImageLoaderComponent } from './image-loader.component'
import { ImageCropperModule } from 'ngx-image-cropper';
import { MatButtonModule } from '@angular/material';



@NgModule({
  declarations: [
    ImageLoaderComponent,
  ],
  imports: [
    CommonModule,
    ImageCropperModule,
    MatButtonModule
  ],
  exports: [
    ImageLoaderComponent,
  ]
})
export class ImageLoaderModule { }
