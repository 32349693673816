import { Injectable } from '@angular/core'
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Observable } from 'rxjs'
import { finalize, delay } from 'rxjs/operators'
import { NgxSpinnerService } from 'ngx-spinner'

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
    constructor(private spinner: NgxSpinnerService) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // this.spinner.show();
        return next.handle(req).pipe(
            finalize(() => {
                setTimeout(() => {
                    // this.spinner.hide();
                }, 1000)
            }),
        )
    }
}
