import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { EffectsModule } from '@ngrx/effects'
import { StoreModule } from '@ngrx/store'
import { SocketManagerEffects } from './socket-manager.effects'
import * as FromReducer from './socket-manager.reducer'


@NgModule({
  declarations: [],
  imports: [
    CommonModule,

    StoreModule.forFeature(
      FromReducer.socketManagerFeatureKey, FromReducer.reducer,
    ),
    EffectsModule.forFeature([SocketManagerEffects]),

  ],
})
export class SocketManagerModule { }
