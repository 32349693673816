import { Component, OnInit } from '@angular/core'
import { FormBuilder, Validators, FormArray, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router'
import { AngularEditorConfig } from '@kolkov/angular-editor'
import { Store } from '@ngrx/store'
import { AppState } from 'src/app/reducers'
import { NewsItem } from '../../models/news.model'
import { NewsHttpService } from '../../services/news-http.service'
import { createNews, editNews } from '../../store/news-feature.actions'

@Component({
  selector: 'app-news-create',
  templateUrl: './news-create.component.html',
  styleUrls: ['./news-create.component.scss'],
})
export class NewsCreateComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<AppState>,
    private router: Router,
    private route: ActivatedRoute,
    private newsHttp: NewsHttpService,
  ) { }


  newsForm
  newsItemToEdit
  currentId

  isEditScreen: boolean = undefined

  toolbarConfig: AngularEditorConfig = {
    toolbarHiddenButtons: [
      [
        'textColor', 'backgroundColor',
        'insertImage', 'insertVideo',
      ],
    ],
    editable: true,
  }

  isActiveChecks = [
    {
      value: true,
      label: 'Activated',
    },
    {
      value: false,
      label: 'Hidden',
    },
  ]

  onImageLoad(evt) {
    // tslint:disable-next-line:no-string-literal
    this.newsForm.controls['imgUrl'].setValue(evt)
  }

  ngOnInit() {


    this.route.params.subscribe((params: Params) => {
      if (params) {
        const { id } = params
        if (id) {

          this.newsHttp.getNewsById(id).subscribe(
            (value: NewsItem) => {
              this.newsItemToEdit = value
              this.initForm(value)
            },
          )
          this.isEditScreen = true
          this.currentId = id
        } else {
          this.isEditScreen = false
        }
      }
    })

    const empty = {
      title: '',
      imgUrl: '',
      content: '',
      tagStr: '',
      activated: false,
      favorite: false,
    }
    this.initForm(empty)
  }

  showValidationMessage(form: FormGroup, name: string ): boolean {
    return form.controls[name].invalid && (form.controls[name].dirty || form.controls[name].touched)
  }

  isInitialized(): boolean {
    return typeof this.isEditScreen === 'boolean'
  }

  initForm(newsItem?: NewsItem) {

    const {
      title = '',
      imgUrl = '',
      content = '',
      tagStr = '',
      activated = false,
      favorite = false,
    } = newsItem

    const isActivatedChecks = new FormArray([
      new FormControl(true),
      new FormControl(false),
    ])

    this.newsForm = this.formBuilder.group({
      title: [title, [Validators.required, Validators.minLength(5)]],
      content: [content, [Validators.required, Validators.minLength(5)]],
      imgUrl: [imgUrl],
      tagStr: [tagStr],
      activated: [ activated.toString(), isActivatedChecks ],
    })

    this.newsForm.valueChanges.subscribe(data => console.log(data))
    this.newsForm.statusChanges.subscribe(data => console.log(data, this.newsForm))

  }



  save() {

    if ( !this.currentId) {
      const { title, content, imgUrl, tagStr, favorite, activated } = this.newsForm.value

      this.store.dispatch(createNews(
        { newsItem: { title, content, imgUrl, activated } },
      ))

    } else {

      const { title, content, imgUrl, tagStr, favorite, activated } = this.newsForm.value

      const dto: NewsItem = {
        ...this.newsItemToEdit, id: this.currentId,
        title, content, imgUrl, tagStr, favorite, activated,

      }

      this.store.dispatch(editNews(
        { newsItem: dto },
      ))

    }

    this.router.navigate(['/cms-nav/news'])
  }

  cancel() {
    this.router.navigate(['/cms-nav/news'])
  }

  isEqual(a: boolean | string, b: boolean | string): boolean {
    return a.toString() === b.toString()
  }


}
