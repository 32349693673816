import { createAction, props } from '@ngrx/store'


export const fetchUser = createAction(
  '[Account] Fetch User',
)
export const fetchUserSuccess = createAction(
  '[Account] Fetch User Success',
  props<{user: any}>(),
)
export const fetchUserFailure = createAction(
  '[Account] Fetch User Failure',
  props<{ error: any}>(),
)


export const setAccountUserId = createAction(
  '[Account] Set Account User Id',
  props<{userId: string}>(),
)

export const setAuthorities = createAction(
  '[Account] Set Authorities',
  props<{authorities: any[]}>(),
)

export const dropAuthorities = createAction(
  '[Account] Drop Authorities',
)
