import { HashLocationStrategy, LocationStrategy } from '@angular/common'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core'
import { HttpModule } from '@angular/http'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { EffectsModule } from '@ngrx/effects'
import { StoreModule } from '@ngrx/store'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner'
import { environment } from '../environments/environment'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { AuthModule } from './auth/auth.module'
import { AuthInterceptor } from './blocks/interceptor/auth.interceptor'
import { LoaderInterceptor } from './blocks/interceptor/loader.interceptor'
import { ChatboxFeatureModule } from './chatbox/ChatboxFeature.module'
import { FaqModule } from './faq/faq.module'
import { CmsnavLayoutModule } from './layouts/cmsnav-layout/cmsnav-layout.module'
import { LoginLayoutModule } from './layouts/login-layout/login-layout.module'
import { NewsModule } from './news/news.module'
import { metaReducers, reducers } from './reducers'
import { SharedModule } from './shared/share.module'
import { WebSocketClient } from './socket/socket-client-service'
import { SocketManagerModule } from './socket/socket-manager.module'
import { TimelineModule } from './timeline/timeline.module'
import { TournamentDataModule } from './tournament-data/tournament-data.module'
// import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
// import { AddQuestionModalModule } from './quiz/components/add-question-modal/add-question-modal.module';
// import { UnreadModule } from './unread/unread/unread.module'
import { VisualLayoutModule } from './visual-layout/visual-layout.module'


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [

    BrowserModule,
    BrowserAnimationsModule,

    SharedModule,
    HttpModule,
    HttpClientModule,
    AppRoutingModule,
    NgxSpinnerModule,
    // FontAwesomeModule,
    CmsnavLayoutModule,
    LoginLayoutModule,
    FaqModule,
    NewsModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot([]),
    ChatboxFeatureModule,
    AuthModule,
    TimelineModule,
    SocketManagerModule,
    VisualLayoutModule,
    TournamentDataModule,
    // UnreadModule,
    // AddQuestionModalModule,
    !environment.production ? StoreDevtoolsModule.instrument() : [],
  ],
  providers: [
    NgxSpinnerService,
    WebSocketClient,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule { }
