import { TournamentShort } from '../models/tournamentShort.interface'

export const extractTournamentShort = ({ id, name, description, imgUrl, regDateTimeClose,
    regDateTimeOpen, startDateTime, status,
    rules, activated, region, playing,
} ): TournamentShort =>
    ({ id, name, description, imgUrl, regDateTimeClose, regDateTimeOpen, startDateTime,
        rules, activated, region, playing, status,
    })

