import { Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class ImageLoaderService {
  constructor(private sanitizer: DomSanitizer) { }

  createSafeUrl(file): SafeResourceUrl {
    try {
      const url = window.URL.createObjectURL(file);
      const safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      return safeUrl;
    } catch (er) {
      console.log(er);
    }
  }
}
