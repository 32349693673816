import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { AppConstants } from '../app.constants'

@Injectable({
  providedIn: 'root',
})

export class TimelineService {


  clickStart( { matchId, tournamentId, userId } ) {
    return this.http.post(
      `${AppConstants.SERVER_API_URL}/api/moderator-rest/user-action/click-start?tourMatchId=${matchId}&tournamentId=${tournamentId}&userExtraId=${userId}`,
        {}, { observe: 'body' },
    )
  }
  clickFinish( { matchId, tournamentId, userId } ) {
    return this.http.post(
      `${AppConstants.SERVER_API_URL}/api/moderator-rest/user-action/click-finish?tourMatchId=${
        matchId}&tournamentId=${tournamentId}&userExtraId=${userId}`,
        {}, { observe: 'body' },
    )
  }

  pauseNextRound(tournamentId) {
    return this.http.post(
      `${AppConstants.SERVER_API_URL}/api/tournaments/${tournamentId}/pauseNextRound`,
      {}, { observe: 'body' },
    )
  }

  resumeNextRound(tournamentId) {
    return this.http.put(
      `${AppConstants.SERVER_API_URL}/api/tournaments/${tournamentId}/startNextRound`,
      {}, { observe: 'body' },
    )
  }


  fetchNotifications(tournamentId) {
    return this.http.get(
      `${AppConstants.SERVER_API_URL}/api/moderator-auto-punishments/notifications?tournamentId=${tournamentId}`,
      { observe: 'body' },
    )
  }

  permitNotification({ notificationId }) {
    return this.http.post(
      `${AppConstants.SERVER_API_URL}/api/moderator-auto-punishments/permit/${notificationId}`,
      { observe: 'body' },
    )
  }

  cancelNotification({ notificationId }) {
    return this.http.post(
      `${AppConstants.SERVER_API_URL}/api/moderator-auto-punishments/cancel/${notificationId}`,
      { observe: 'body' },
    )
  }

  notifyAllUsers(dto) {
    return this.http.post(
      `${AppConstants.SERVER_API_URL}/api/moderator-rest/send-push`,
      dto, { observe: 'body' },
    )
  }

  constructor(
    private http: HttpClient,
  ) { }
}
