import { Injectable } from '@angular/core'
import { Actions } from '@ngrx/effects'




@Injectable()
export class AccountEffects {

  // loadAccounts$ = createEffect(() => {
  //   return this.actions$.pipe(

  //     ofType(AccountActions.loadAccounts),
  //     concatMap(() =>
  //       /** An EMPTY observable only emits completion. Replace with your own observable API request */
  //       EMPTY.pipe(
  //         map(data => AccountActions.loadAccountsSuccess({ data })),
  //         catchError(error => of(AccountActions.loadAccountsFailure({ error })))),
  //     ),
  //   )
  // })



  constructor(private actions$: Actions) {}

}
