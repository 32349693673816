import { Component, Inject, OnInit } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

@Component({
  selector: 'app-push-to-all-modal',
  templateUrl: './push-to-all-modal.component.html',
  styleUrls: ['./push-to-all-modal.component.scss'],
})
export class PushToAllModalComponent implements OnInit {

  notifyForm: any

  constructor(
    public dialogRef: MatDialogRef<PushToAllModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
      this.initForm()
  }

  initForm() {

    this.notifyForm = this.formBuilder.group({
      title: ['', [Validators.required]],
      message: ['', [Validators.required]],
    })
  }

  onClickYes(): void {
    this.dialogRef.close(this.notifyForm.value)
  }

  onClickNo(): void {
    this.dialogRef.close(false)
  }


}
