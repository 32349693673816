import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-ajax-team',
  templateUrl: './ajax-team.component.html',
  styleUrls: ['./ajax-team.component.scss'],
})
export class AjaxTeamComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
