import { BehaviorSubject } from 'rxjs';
import { AppConstants } from '../app.constants';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LoginPreStepService {
    private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public currentUserId: any = -1;
  
    constructor(
        private http: HttpClient
    ) { }
    
    get isLoggedIn() {
      return this.loggedIn.asObservable();
    }

    setLoggedIn(){
        this.loggedIn.next(true)
    }

    setLoggedOut(){
        this.loggedIn.next(false);
    }

    login(username: string, password: string){
        
        const data = {
            username: username,
            password: password,
        };
        return this.http.post(AppConstants.SERVER_API_URL + '/api/authenticate', data, { observe: 'response' });
    }

    vertifyCode(userId: any, vertifyCode: string){
        const data = {
            userId: userId,
            loginVerificationCode: vertifyCode,
        };

        return this.http.post(AppConstants.SERVER_API_URL + '/api/authenticateSecondStep', data, { observe: 'response' });

    }

  }