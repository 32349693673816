import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConstants } from '../app.constants';
@Injectable({ providedIn: 'root' })
export class UploadImgService {
    constructor(private http: HttpClient) {}
    addImage(req: any): Observable<any> {
        return this.http.post<any>(AppConstants.SERVER_API_URL + '/api/upload', req);
    }
}
