import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LoginPreStepService } from './login-prestep.service';
import { Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';

export class LoginHomeGuard implements CanActivate {
    constructor(private authService: LoginPreStepService, private router: Router) {}
  
    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
      return this.authService.isLoggedIn.pipe(
        take(1),
        map((isLoggedIn: boolean) => {
          if (!isLoggedIn) {
            this.router.navigateByUrl('login/step1');
            return false;
          }
          return true;
        })
      );
    }
  }