import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AuthGuard } from './auth/auth.guard'
import { Authorities } from './auth/models/Authorities.enum'
import { PageComponent } from './visual-layout/page/page.component'

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard/home-user',
    pathMatch: 'full',
    data: {
      authorities: [Authorities.Moderator],
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard',
    data: {
      authorities: [Authorities.Moderator],
    },
    canActivate: [AuthGuard],
    loadChildren: './layouts/dashbroad-layout/dashbroad-layout.module#DashbroadLayoutModule',
  },
  {
    path: 'cms-nav',
    data: {
      authorities: [Authorities.HeadModerator, Authorities.SuperModerator],
    },
    canActivate: [AuthGuard],
    loadChildren: './layouts/cmsnav-layout/cmsnav-layout.module#CmsnavLayoutModule',
  },
  {
    path: 'login',
    loadChildren: './layouts/login-layout/login-layout.module#LoginLayoutModule',
  },
  {
    path: 'visual-layout',
    component: PageComponent,
  },
  {
    path: '**',
    redirectTo: 'dashboard/home-user',
  },

]

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
