import { Authorities } from 'src/app/auth/models/Authorities.enum';
import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { LoginPreStepService } from '../auth/login-prestep.service'
import { LoginService } from '../auth/login.service'
import { AppState } from '../reducers'
import { AccountService } from '../auth/account.service';
import { catchError } from 'rxjs/operators'
import { of } from 'rxjs'


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup
  loginVertifyForm: FormGroup

  isShowLoginButtonHome = true

  isActiveRightPane = false


  isShowLoginForm = false
  isLoginFormButtonDisable = false

  isShowLoginFormErrorEmail = false
  loginFormErrorEmailMessage = ''

  isShowLoginFormErrorPassword = false
  loginFormErrorPasswordMessage = ''


  isShowVerfityCodeForm = false

  isShowVerfityCodeFormError = false
  verfityCodeFormErrorMessage = ''



  constructor(
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private loginPreStepService: LoginPreStepService,
    private router: Router,
    private store: Store<AppState>,
    private account: AccountService,
  ) { }

  ngOnInit() {
    this.loginService.clearToken()

    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    })

    this.loginVertifyForm = this.formBuilder.group({
      codeSMS: ['', Validators.required],
    })
  }

  onClickLoginToStep1(): void {
    this.isShowLoginButtonHome = false
    this.isActiveRightPane = true
    this.isShowLoginForm = true

    // test

    // this.isLoginFormError = true;
    // this.isShowLoginFormErrorEmail = true;
    // this.loginFormErrorEmailMessage = 'error email';

    // this.isShowLoginFormErrorPassword = true;
    // this.loginFormErrorPasswordMessage = 'error pwd';
  }

  onSubmitLogin(): void {
    console.log('onSubmitLogin()....')

    // this.isLoginFormButtonDisable = true;

    if (this.loginForm.invalid) {
      console.log('loginForm username:::', this.loginForm.get('username').invalid)
      console.log('loginForm password:::', this.loginForm.get('password').invalid)

      // if(this.loginForm.get('username').invalid){
      //   this.isShowLoginFormErrorEmail = true;
      //   this.loginFormErrorEmailMessage = 'Email is required.';
      // }
      // if(this.loginForm.get('password').invalid){
      //   this.isShowLoginFormErrorPassword = true;
      //   this.loginFormErrorPasswordMessage = 'Password is required.';
      // }

      return
    }
    console.log('loginForm value:::', this.loginForm.value)

    this.isShowLoginFormErrorEmail = false
    this.loginFormErrorEmailMessage = ''

    this.isShowLoginFormErrorPassword = false
    this.loginFormErrorPasswordMessage = ''

    this.loginPreStepService.login(this.loginForm.value.username, this.loginForm.value.password).
    pipe(
      catchError(er => {
        this._handleLoginHttpError(er);
        return of(er);
      })
    )
    .subscribe( (result)=> {
      console.log('this.loginPreStepService.login result:::', result)

      if(result && result.body) {

        const resultBody: any = result.body
        // data: "username"
        // status: 404
        // title: "Account does not exist!"

        this._handleLoginStatus(resultBody);



      }

    })

  }

  onGoStep2(): void {
    this.isShowLoginForm = false
    this.isShowVerfityCodeForm = true
  }

  onSubmitVertifyCode(): void {
    console.log('onSubmitVertifyCode()....')

    if (this.loginVertifyForm.invalid) {
      console.log('loginForm password:::', this.loginForm.get('password').invalid)

      // if(this.loginVertifyForm.get('codeSMS').invalid){
      //   this.isShowVerfityCodeFormError = true;
      //   this.verfityCodeFormErrorMessage = 'Code from SMS is required.';
      // }

      return
    }
    console.log('loginVertifyForm value:::', this.loginVertifyForm.value)
    this.isShowVerfityCodeFormError = false
    this.verfityCodeFormErrorMessage = ''

    const payloadData = {
      userId: this.loginPreStepService.currentUserId,
      loginVerificationCode: this.loginVertifyForm.value.codeSMS,
    }

    this.loginService.login(payloadData)
      .then((result: any) => {

        console.log('this.loginService.login() result:::', result)

        if(result) {
          switch (result.status) {
            case 200:

              break
            case 400:
                this.isShowVerfityCodeFormError = true
                this.verfityCodeFormErrorMessage = result.title
                break

            default:
              break
          }
        }

        // if (this.router.url === '/register' || /^\/activate\//.test(this.router.url) || /^\/reset\//.test(this.router.url)) {
        //   this.router.navigate(['']);
        // }

        // this.eventManager.broadcast({
        //   name: 'authenticationSuccess',
        //   content: 'Sending Authentication Success'
        // });

        // previousState was set in the authExpiredInterceptor before being redirected to login modal.
        // since login is successful, go to stored previousState and clear previousState

        // const redirect = this.stateStorageService.getUrl();
        // if (redirect) {
        //   this.stateStorageService.storeUrl(null);
        //   this.router.navigate([redirect]);
        // }
      })

  }

  private _handleLoginStatus(resultBody): void {
    switch (resultBody.status) {
      case 200:

        localStorage.setItem('authenticationToken', resultBody.data.id_token);
          this.account.fetch()
          .pipe(
            catchError(er => {
              this.router.navigate(['/dashboard/home'])
              return of (er);
            })
          )
          .subscribe(res => {
            const authorities = res && res.body && res.body.authorities;
            if (!authorities) {
              this.router.navigate(['/dashboard/home'])
              return;
            }
           // debugger;
            const isSuperModerator = authorities.includes('ROLE_HEAD_MODERATOR');
            const isHeadModerator = authorities.includes('ROLE_SUPER_MODERATOR');
            console.log(isSuperModerator, isHeadModerator);
            if (isSuperModerator || isHeadModerator) {
              this.router.navigate(['/dashboard/home']);
            } else {
              this.router.navigate(['/dashboard/tournament'])
            }
          });

        this.loginPreStepService.currentUserId = resultBody.data
        // this.loginPreStepService.setLoggedIn();
        // this.onGoStep2();
        break
      case 404:
        if(resultBody.data && resultBody.data === 'username') {
          this.isShowLoginFormErrorEmail = true
          this.loginFormErrorEmailMessage = resultBody.title
        }
        break
      case 400:
        if(resultBody.data && resultBody.data === 'password') {
          this.isShowLoginFormErrorPassword = true
          this.loginFormErrorPasswordMessage = resultBody.title
        }
        break

      default:
        break
    }
  }

  private _handleLoginHttpError(errorResponse: any): void {
    if (!errorResponse.error) { return; }
    const { error } = errorResponse;
    console.log(errorResponse)
    if (error.title = 'Incorrect password') {
      this.isShowLoginFormErrorPassword = true
      this.loginFormErrorPasswordMessage = 'Password is Incorrect !'
    }

  }

}


$(document).ready(function() {
    $('.show-hide-pass').click(function() {
        let input = $(this).siblings('.input_password');

        if ( !input.hasClass('pass-visible') ) {
            input.attr('type', '').addClass('pass-visible');
        } else {
            input.attr('type', 'password').removeClass('pass-visible');
        }
    });
});