import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as fromNewsFeature from './news-feature.reducer'

export const selectNewsFeatureState = createFeatureSelector<fromNewsFeature.NewsState>(
  fromNewsFeature.newsFeatureFeatureKey,
)

export const selectNewsList = createSelector(
  selectNewsFeatureState,
  // tslint:disable-next-line
  ({ newsList }) => Object.values(newsList).sort((a: any, b: any) => parseInt(b.id) - parseInt(a.id)),
)

export const selectNewsCount = createSelector(
  selectNewsFeatureState,
  ({ newsLength }) => newsLength,
)

export const selectNewsPaginator = createSelector(
  selectNewsFeatureState,
  ( { newsPaginator } ) => newsPaginator,
)

export const selectNewsListSearchBar = createSelector(
  selectNewsFeatureState,
  ( { newsListSearch } ) => newsListSearch,
)
