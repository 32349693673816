import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { AppConstants } from 'src/app/app.constants'
// import { SendMessageService } from 'src/app/services/send-message.service'
import { TourMatchService } from 'src/app/services/tour-match.service'
import { CARD_TYPE } from '../tournament.constants'

@Component({
  selector: 'app-tournament-match-punish-card',
  templateUrl: './tournament-match-punish-card.component.html',
  styleUrls: ['./tournament-match-punish-card.component.scss'],
})
export class TournamentMatchPunishCardComponent implements OnInit {
  sendCardDTO: any
  user: any
  content = ''
  isSending: boolean
  title = 'You got a card'
  dialogTitle = ''
  baseUrlImage: string = AppConstants.BASE_IMAGE_URL
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    // private sendMessageService: SendMessageService,
    private dialogRef: MatDialogRef<TournamentMatchPunishCardComponent>,
    private tourMatchService: TourMatchService,
  ) {
    // dialogRef.disableClose = true;
  }

  ngOnInit() {
    console.log(this.data, this.data.cardType)

    this.isSending = true
    this.dialogTitle = this.data.cardType ? `${this.data.cardType === CARD_TYPE.YELLOW
      ? 'Yellow'
      : 'Red' } Card` : 'KICK OUT'
    this.content = 'Please enter a reason'
  }



  onSend(): void {



    this.isSending = false
    if (this.data.card) {
      this.pushCard()
    } else {
      this.kickOutPlayer()
    }
  }

  private onSendError() {
    this.isSending = false
  }

  onCloseButton() {
    this.dialogRef.close()
  }

  pushCard() {
    this.sendCardDTO = {
      userId: this.data.player.userExtraId,
      cardType: this.data.cardType,
      reason: this.content,
      tournamentId: this.data.tournamentId,
      tournamentName: this.data.tournamentName,
      tourMatchId: this.data.tourMatchId,
    }
    console.log(this.sendCardDTO)
    if (this.sendCardDTO.reason && this.sendCardDTO.reason.trim().length > 0) {
      this.tourMatchService.sendCard(this.sendCardDTO).subscribe(rs => {
        console.log(rs)
        this.dialogRef.close(true)
      }, () => this.onSendError())
    } else {
      this.dialogRef.close(false)
    }
  }

  kickOutPlayer() {
    if (this.content && this.content.trim().length > 0) {
      this.tourMatchService
        .queryKickOutPlayer({
          tournamentId: this.data.tournamentId,
          tourMatchId: this.data.tourMatchId,
          userExtraId: this.data.player.userExtraId,
          reasonKicked: this.content,
        })
        .subscribe(
          (res) => {
            console.log(res)
            this.dialogRef.close(true)
          },
          (err) => console.log(err),
        )
    }
  }

}
