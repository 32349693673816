import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { AppConstants } from '../../app.constants'
import { MarkAsReadDTO } from '../models/mark-as-read.model'


@Injectable({
  providedIn: 'root',
})
export class UnreadHttpService {

  fetchUnreadInfo() {
    return this.http.get(`${AppConstants.SERVER_API_URL}/api/messages-info/`, { observe: 'body' } )
  }

  markAsRead( dto: MarkAsReadDTO[] ) {
    return this.http.post(`${AppConstants.SERVER_API_URL}/api/messages-info/read` , dto, { observe: 'body'})
  }

  constructor(
    private http: HttpClient,
  ) { }
}
