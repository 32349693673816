import { Injectable } from '@angular/core'
import { HttpClient, HttpResponse, HttpParams } from '@angular/common/http'
import { Observable } from 'rxjs'
import * as moment from 'moment'
import { map } from 'rxjs/operators'

import { AppConstants } from '../app.constants'
import { createRequestOption } from '../utils/request-util'
import { ITourMatch } from '../shared/model/tour-match.model'


type EntityResponseType = HttpResponse<ITourMatch>
type EntityArrayResponseType = HttpResponse<ITourMatch[]>

@Injectable({ providedIn: 'root' })
export class TourMatchService {
    public resourceUrl = AppConstants.SERVER_API_URL + '/api/tour-matches'
    public resourceUrlForDecideTheWinner = AppConstants.SERVER_API_URL + '/api/match-handle/decideTheWinner'
    public userChatInGameUrl = AppConstants.SERVER_API_URL + '/api/match-handle/userChatInGame'
    public sendCardUrl = AppConstants.SERVER_API_URL + '/api/match-handle/cmsModeratorSendCard'

    constructor(protected http: HttpClient) { }

    create(tourMatch: ITourMatch): Observable<EntityResponseType> {
        const copy = this.convertDateFromClient(tourMatch)
        return this.http
            .post<ITourMatch>(this.resourceUrl, copy, { observe: 'response' })
            .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)))
    }

    update(tourMatch: ITourMatch): Observable<EntityResponseType> {
        const copy = this.convertDateFromClient(tourMatch)
        return this.http
            .put<ITourMatch>(this.resourceUrl, copy, { observe: 'response' })
            .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)))
    }

    find(id: number): Observable<EntityResponseType> {
        return this.http
            .get<ITourMatch>(`${this.resourceUrl}/${id}`, { observe: 'response' })
            .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)))
    }

    queryKickOutPlayer(req?: any): Observable<any> {
        const options = createRequestOption(req)
        return this.http.get<any>(this.resourceUrl + '/cmsKickOutPlayer', { params: options, observe: 'response' })
    }

    cancelThisMatch(req?: any): Observable<any> {
        const options = createRequestOption(req)
        return this.http.get<any>(this.resourceUrl + '/cmsModeratorCancelTheMatch', { params: options, observe: 'response' })
    }

    queryByTournamentId(req?: any): Observable<EntityArrayResponseType> {
        const options = createRequestOption(req)
        return this.http
            .get<ITourMatch[]>(this.resourceUrl + '/cmsGetMatchesByTournamentId', { params: options, observe: 'response' })
            .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)))
    }

    cmsModeratorGetAllListToManage(req?: any): Observable<EntityArrayResponseType> {
        const options = createRequestOption(req)
        return this.http
            .get<ITourMatch[]>(this.resourceUrl + '/cmsModeratorGetAllListToManage', { params: options, observe: 'response' })
            .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)))
    }

    query(req?: any): Observable<EntityArrayResponseType> {
        const options = createRequestOption(req)
        return this.http
            .get<ITourMatch[]>(this.resourceUrl, { params: options, observe: 'response' })
            .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)))
    }

    search(input: any): Observable<any> {
        return this.http.get<ITourMatch[]>(this.resourceUrl + '/cmsSearch', { params: input, observe: 'response' })
    }

    delete(id: number): Observable<HttpResponse<any>> {
        return this.http.delete<any>(`${this.resourceUrl}/${id}`, { observe: 'response' })
    }

    userChatInGame(input: any): Observable<any> {
        return this.http.post<any>(this.userChatInGameUrl, input, { observe: 'response' })
    }

    decideTheWinner(input: any): Observable<any> {
        return this.http.post<any>(this.resourceUrlForDecideTheWinner, input, { observe: 'response' })
    }

    sendCard(sendCardDTO: any): Observable<any> {
        return this.http.post<any>(this.sendCardUrl, sendCardDTO, { observe: 'response' })
    }

    protected convertDateFromClient(tourMatch: ITourMatch): ITourMatch {
        const copy: ITourMatch = Object.assign({}, tourMatch, {
            startDateTime: tourMatch.startDateTime != null && tourMatch.startDateTime.isValid() ? tourMatch.startDateTime.toJSON() : null,
            finishDateTime:
                tourMatch.finishDateTime != null && tourMatch.finishDateTime.isValid() ? tourMatch.finishDateTime.toJSON() : null,
            appearBothDateTime:
                tourMatch.appearBothDateTime != null && tourMatch.appearBothDateTime.isValid()
                    ? tourMatch.appearBothDateTime.toJSON()
                    : null,
            matchStartDateTime:
                tourMatch.matchStartDateTime != null && tourMatch.matchStartDateTime.isValid()
                    ? tourMatch.matchStartDateTime.toJSON()
                    : null,
            matchFinishDateTime:
                tourMatch.matchFinishDateTime != null && tourMatch.matchFinishDateTime.isValid()
                    ? tourMatch.matchFinishDateTime.toJSON()
                    : null,
        })
        return copy
    }

    protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
        if (res.body) {
            res.body.startDateTime = res.body.startDateTime != null ? moment(res.body.startDateTime) : null
            res.body.finishDateTime = res.body.finishDateTime != null ? moment(res.body.finishDateTime) : null
            res.body.appearBothDateTime = res.body.appearBothDateTime != null ? moment(res.body.appearBothDateTime) : null
            res.body.matchStartDateTime = res.body.matchStartDateTime != null ? moment(res.body.matchStartDateTime) : null
            res.body.matchFinishDateTime = res.body.matchFinishDateTime != null ? moment(res.body.matchFinishDateTime) : null
        }
        return res
    }

    protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
        if (res.body) {
            res.body.forEach((tourMatch: ITourMatch) => {
                tourMatch.startDateTime = tourMatch.startDateTime != null ? moment(tourMatch.startDateTime) : null
                tourMatch.finishDateTime = tourMatch.finishDateTime != null ? moment(tourMatch.finishDateTime) : null
                tourMatch.appearBothDateTime = tourMatch.appearBothDateTime != null ? moment(tourMatch.appearBothDateTime) : null
                tourMatch.matchStartDateTime = tourMatch.matchStartDateTime != null ? moment(tourMatch.matchStartDateTime) : null
                tourMatch.matchFinishDateTime = tourMatch.matchFinishDateTime != null ? moment(tourMatch.matchFinishDateTime) : null
            })
        }
        return res
    }

    // tslint:disable-next-line:max-line-length
    getTourMatchList(moderatorId: any, tournamentId: any, sectorIndex: any, stageIndex: any, status?: string): Observable<HttpResponse<any>> {
        const params = new HttpParams()
            .set('moderatorId', moderatorId)
            .set('tournamentId', tournamentId)
            .set('stageIndex', stageIndex)
            .set('status', status)
            .set('sectorIndex', sectorIndex !== '-1' ? sectorIndex : '')
            
        // tslint:disable-next-line:max-line-length
        return this.http.get(AppConstants.SERVER_API_URL + `/api/tour-matches/cmsModeratorTournamentMatches`, { params, observe: 'response' })
    }

    restartTourMatch(matchId: string): Observable<HttpResponse<any>> {
        return this.http.put(AppConstants.SERVER_API_URL + `/api/tour-matches/${matchId}/restart`, null, { observe: 'response' })
    }

    cancelTourMatch(matchId: string): Observable<HttpResponse<any>> {
        return this.http.post(AppConstants.SERVER_API_URL + `/api/tour-matches/${matchId}/cancel`, null, { observe: 'response' })
    }
}
