import { createAction, props } from '@ngrx/store'
import { PaginatorState } from '../../shared/model/paginatorState.model'
import { NewsItemCreateDTO } from '../models/news.model'

export const loadNews = createAction(
  '[NewsFeature] Load News',
)

export const loadNewsSuccess = createAction(
  '[NewsFeature] Load News Success',
  props<{ data: any, newsLength: number }>(),
)

export const loadNewsFailure = createAction(
  '[NewsFeature] Load News Failure',
  props<{ error: any }>(),
)

export const createNews = createAction(
  '[NewsFeature] Create News',
  props<{ newsItem: NewsItemCreateDTO }>(),
)

export const createNewsSuccess = createAction(
  '[NewsFeature] Create News Success',
  props<{ data: any }>(),
)

export const createNewsFailure = createAction(
  '[NewsFeature] Create News Failure',
  props<{ error: any }>(),
)

export const editNews = createAction(
  '[NewsFeature] Edit News',
  props<{ newsItem: any }>(),
)

export const editNewsSuccess = createAction(
  '[NewsFeature] Edit News Success',
  props<{ data: any }>(),
)

export const editNewsFailure = createAction(
  '[NewsFeature] Edit News Failure',
  props<{ error: any }>(),
)

export const deleteNews = createAction(
  '[NewsFeature] Delete News',
  props<{ newsItemId: string }>(),
)

export const deleteNewsSuccess = createAction(
  '[NewsFeature] Delete News Success',
  props<{ data: any }>(),
)

export const deleteNewsFailure = createAction(
  '[NewsFeature] Delete News Failure',
  props<{ error: any }>(),
)

export const getNewsById = createAction(
  '[NewsFeature] Get By Id News',
  props<{ newsId: string }>(),
)

export const getNewsByIdSuccess = createAction(
  '[NewsFeature] Get By Id News Success',
  props<{ data: any }>(),
)

export const getNewsByIdFailure = createAction(
  '[NewsFeature] Get By Id News Failure',
  props<{ error: any }>(),
)

export const setNewsPaginator = createAction(
  '[NewsFeature] Set News Paginator',
  props<{ state: PaginatorState }>(),
)

export const setSearchString = createAction(
  '[NewsFeature] Set News Search',
  props<{newsListSearch: string}>(),
)
