import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatPaginatorModule } from '@angular/material/paginator'
import { RouterModule, Routes } from '@angular/router'
import { EffectsModule } from '@ngrx/effects'
import { StoreModule } from '@ngrx/store'
import { FaqCreateComponent } from './components/faq-create/faq-create.component'
import { FaqListItemComponent } from './components/faq-list-item/faq-list-item.component'
import { FaqListComponent } from './components/faq-list/faq-list.component'
import { FaqFeatureEffects } from './store/faq-feature.effects'
import * as fromFaqReducer from './store/faq-feature.reducer'
import { ReactiveFormsModule } from '@angular/forms'
import { MatPaginatorIntlFAQList } from '../shared/components/ajax-paginator.provider'
import { MatPaginatorIntl } from '@angular/material'

const routes: Routes = [
  { path: '', component: FaqListComponent },
  { path: 'new', component: FaqCreateComponent },
  { path: ':id/edit', component: FaqCreateComponent },
]

@NgModule({
  declarations: [FaqListComponent, FaqListItemComponent, FaqCreateComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    StoreModule.forFeature(fromFaqReducer.faqFeatureFeatureKey, fromFaqReducer.reducer),
    EffectsModule.forFeature([FaqFeatureEffects]),
    RouterModule.forChild(routes),
    MatPaginatorModule,
  ],
  providers: [
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlFAQList },
  ],
})
export class FaqModule { }
