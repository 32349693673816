import { Injectable } from '@angular/core'
import { HttpClient, HttpResponse } from '@angular/common/http'
import { Observable, Subject } from 'rxjs'
import { AppConstants } from '../app.constants'
import { Account } from './account.model'
import { Store } from '@ngrx/store'
import { AppState } from '../reducers'
import { setAuthorities, dropAuthorities, fetchUserSuccess } from './account.actions';

@Injectable({ providedIn: 'root' })
export class AccountService {
    private userIdentity: any
    private authenticated = false
    private authenticationState = new Subject<any>()

    constructor(
        private http: HttpClient,
        private store: Store<AppState>,
        ) { }

    fetch(): Observable<HttpResponse<Account>> {
        return this.http.get<Account>(AppConstants.SERVER_API_URL + '/api/account', { observe: 'response' })
    }

    save(account: any): Observable<HttpResponse<any>> {
        return this.http.post(AppConstants.SERVER_API_URL + '/api/account', account, { observe: 'response' })
    }

    authenticate(identity) {
        this.userIdentity = identity

        this.authenticated = identity !== null
        this.authenticationState.next(this.userIdentity)
    }

    hasAnyAuthority(authorities: string[]): boolean {

        if (!this.authenticated || !this.userIdentity || !this.userIdentity.authorities) {
            return false
        }

        for (let i = 0; i < authorities.length; i++) {
            if (this.userIdentity.authorities.includes(authorities[i])) {
                return true
            }
        }

        return false
    }

    hasAuthority(authority: string): Promise<boolean> {
        if (!this.authenticated) {
            return Promise.resolve(false)
        }

        return this.identity().then(
            id => {
                return Promise.resolve(id.authorities && id.authorities.includes(authority))
            },
            () => {
                return Promise.resolve(false)
            },
        )
    }

    identity(force?: boolean): Promise<any> {
        if (force) {
            this.userIdentity = undefined
        }

        // check and see if we have retrieved the userIdentity data from the server.
        // if we have, reuse it by immediately resolving
        if (this.userIdentity) {
            return Promise.resolve(this.userIdentity)
        }

        // retrieve the userIdentity data from the server, update the identity object, and then resolve.
        return this.fetch()
            .toPromise()
            .then(response => {
                const account = response.body
                if (account) {
                    this.userIdentity = account
                    this.authenticated = true

                    this.store.dispatch(fetchUserSuccess({ user: account}))


                    const { authorities = [] } =  account
                    this.store.dispatch(setAuthorities({ authorities }))
                    // After retrieve the account info, the language will be changed to
                    // the user's preferred language configured in the account setting
                    // const langKey = this.sessionStorage.retrieve('locale') || this.userIdentity.langKey;
                    // this.languageService.changeLanguage(langKey);
                } else {
                    this.userIdentity = null
                    this.authenticated = false
                    this.store.dispatch(dropAuthorities())
                }
                this.authenticationState.next(this.userIdentity)
                return this.userIdentity
            })
            .catch(err => {
                this.userIdentity = null
                this.authenticated = false
                this.authenticationState.next(this.userIdentity)
                return null
            })
    }

    isAuthenticated(): boolean {
        return this.authenticated
    }

    isIdentityResolved(): boolean {
        return this.userIdentity !== undefined
    }

    getAuthenticationState(): Observable<any> {
        return this.authenticationState.asObservable()
    }

    getImageUrl(): string {
        return this.isIdentityResolved() ? this.userIdentity.imageUrl : null
    }

    getFirstName(): string {
        return this.isIdentityResolved() ? this.userIdentity.firstName : null
    }

    getUserId() {
        return this.isIdentityResolved() ? this.userIdentity.id : null
    }

    getEmail(): string {
        return this.isIdentityResolved() ? this.userIdentity.email : null
    }

    checkImageUrlContain(path: string): boolean {
        if (path) {
            if (path.indexOf('http') == 0) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    }
    getUserProfile(id: number): Observable<HttpResponse<any>> {
        return this.http.get<any>(AppConstants.SERVER_API_URL + '/api/account/getUserProfile/' + id, { observe: 'response' })
    }

    // ROLE_ADMIN
    // ROLE_HEAD_MODERATOR
    // ROLE_MODERATOR
    // ROLE_SUPER_MODERATOR
    // ROLE_USER

    // localStorage.setItem('userId', account.id);
    // localStorage.setItem('email', account.email);
    // localStorage.setItem('authorities', account.authorities);

    hasRoleModerator(): boolean {
        if (this.isIdentityResolved()) {
            return this.userIdentity.authorities.includes('ROLE_MODERATOR')
        }
        return false
    }

    hasRoleHeadModerator(): boolean {
        if (this.isIdentityResolved()) {
            return this.userIdentity.authorities.includes('ROLE_HEAD_MODERATOR')
        }
        return false
    }
    hasRoleSuperModerator(): boolean {
        if (this.isIdentityResolved()) {
            return this.userIdentity.authorities.includes('ROLE_SUPER_MODERATOR')
        }
        return false
    }

    clearIdentity(): void {
        this.userIdentity = null
    }
}
