import { createAction, props } from '@ngrx/store'

export const loadUnreadInfo = createAction(
  '[UnreadFeature] Load Unread info',
)

export const loadUnreadInfoSuccess = createAction(
  '[UnreadFeature] Load Unread info Success',
  props<{ data: any }>(),
)

export const loadUnreadInfoFailure = createAction(
  '[UnreadFeature] Load Unread info Failure',
  props<{ error: any }>(),
)
