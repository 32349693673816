import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core'
import { Store } from '@ngrx/store'
import { AppState } from '../../../reducers/index'
import { Faq } from '../../models/faq.model'
import { deleteFaq } from '../../store/faq-feature.actions'
import { Router } from '@angular/router'

@Component({
  selector: 'app-faq-list-item',
  templateUrl: './faq-list-item.component.html',
  styleUrls: ['./faq-list-item.component.scss'],
})
export class FaqListItemComponent implements OnInit {

  @Input()
  faq: Faq

  @Input()
  even: boolean

  @Input()
  index

  onFaqClick(evt) {
    const isDelete = evt.target.getAttribute('data-delete')
    isDelete 
    ? this.onClickDelete()
    : this.onClickEdit()
  }

  onClickDelete() {
    this.store.dispatch(deleteFaq( { faqId: this.faq.id}))
  }

  onClickEdit() {
    this.router.navigate(['/cms-nav/faq', this.faq.id, 'edit'])
  }

  constructor(
    private store: Store<AppState>,
    private router: Router,
  ) { }

  ngOnInit() { }

}
