import { NgModule } from '@angular/core'
import { StoreModule } from '@ngrx/store'
import * as fromChatbox from '../reducers/chatbox.reducer'
import { EffectsModule } from '@ngrx/effects'
import { ChatboxEffects } from '../effects/chatbox.effects'
import * as fromUnread from '../unread/store/unread-feature.reducer'

@NgModule({
    declarations: [
    ],
    imports: [
        StoreModule.forFeature(fromChatbox.chatboxFeatureKey,
            // {
                fromChatbox.reducer,
                // unread: fromUnread.reducer,
            // }
            ),
        EffectsModule.forFeature([ChatboxEffects]),
    ],
})

export class ChatboxFeatureModule {}
