import { HttpErrorResponse, HttpResponse } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { ActivatedRoute, Params } from '@angular/router'
import { Observable } from 'rxjs'
import { CategoryService } from 'src/app/services/category.service'
import { TutorialsService } from 'src/app/services/tutorials.service'
import { UploadImgService } from 'src/app/services/upload-img.service'
import { Catergory } from 'src/app/shared/model/catergory.model'
import { ITutorials } from 'src/app/shared/model/tutorials.model'
import { ARRAY_PRICE, ARRAY_STATUS } from '../tutorials.constants'
@Component({
  selector: 'app-tutorials-create',
  templateUrl: './tutorials-create.component.html',
  styleUrls: ['./tutorials-create.component.scss'],
})
export class TutorialsCreateComponent implements OnInit {
  tutorials: ITutorials = {}
  file: any
  imgURL: any
  tutorialID: any
  categoryList: any = []
  show = false
  categorySelect: any
  numberSelect = 0
  categorys: Catergory[]= []
  selectable = true
  removable = true
  creatorList: any
  tutorialsList: any
  categoryID: any
  free = true
  statusList = ARRAY_STATUS
  priceList = ARRAY_PRICE
  disable = true
  price: number

  constructor(
    private tutorialsService: TutorialsService,
    private formbuilder: FormBuilder,
    private route: ActivatedRoute,
    private serviceUpload: UploadImgService,
    private categoryService: CategoryService,
  ) { }

  editForm = this.formbuilder.group({
    title : [null,Validators.required],
    status : [],
    videoUrl : [null,Validators.required ],
    price :  [''],

  })
  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      console.log('params:::', params)
      if (params) {
        this.tutorialID = params.id
        this.getTutorial(this.tutorialID)
      }
    })
    this.getCategoryList()
    this.getListCreater()
  }

  getTutorial(id: any) {
    if (id) {
      this.tutorialsService.getTutorialEdit(id).subscribe(
        (result)=> {
         if (result && result.body) {
           this.tutorials = result.body
           this.update(this.tutorials)

           this.isShow()
           this.imgURL = this.tutorials.thumbnail
         }
        },
      )
    }
    console.log('ListTutorials :::',this.tutorialsList)

  }
  getListCreater() {
    this.tutorialsService.getTutorialList(0,1,'').subscribe(
      (result)=> {
        this.tutorialsList = result.body
        console.log('getListCreater()  tutorialsList :::',this.tutorialsList)
      },
    )
  }
  update(tutorials: ITutorials) {
    // console.log("update() tutorials::: zozozozo",tutorials.tutorialCategoryId);

    this.isShow()
    this.editForm.patchValue({
      title : tutorials.title,
      videoUrl : tutorials.videoUrl,
      price : tutorials.chargeCoin,
    })
    console.log('categorys[] ===',tutorials)
    if(tutorials.tutorialCategories) {
      tutorials.tutorialCategories.forEach(element => {
        this.categoryList.push({id: element.id, name: element.name})
        console.log('categorys[] ===',this.categorys)
      })
    }

    this.categoryList.map( item => {
      console.log(this.categorys, item)
    })
    console.log('tutorials.activated :::: >>>>',tutorials.activated)

    this.statusList.forEach(x => x.isChecked = false)

    try {
      this.statusList.find(x => x.code === tutorials.activated).isChecked = true
    } catch (error) {
        console.log(error)
    }

    this.priceList.forEach(x=> x.isChecked = false)
    if(tutorials.chargeCoin === 0) {
          this.priceList.find(x => x.value === 'free').isChecked = true
          this.disable = false
        } else {
          this.priceList.find(x => x.value === 'price_free').isChecked = true
        }

    // this.isShow()
    // this.categorys.forEach(element => {
    //   for (let i = 0; i < this.categoryList.length; i++) {
    //     if (element.id === this.categoryList[i].id) {
    //       console.log('isShow() element:::', element)
    //       element.isChecked = true
    //       console.log('element.isChecked =', element.isChecked)
    //     }
    //   }
    // })
  }

  save() {
    this.tutorials.title = this.editForm.get(['title']).value
    this.tutorials.videoUrl = this.editForm.get(['videoUrl']).value
    this.tutorials.thumbnail = this.imgURL
    this.tutorials.tutorialCategories = this.categoryList
    this.tutorials.videoFormat = 1
    this.tutorials.beginAvailable = new Date()
    console.log('this.tutorials.thumbnail :::'+this.tutorials.thumbnail)
    console.log('this.tutorials.videoUrl :::'+this.tutorials.videoUrl)
    console.log('this.tutorials.title :::'+this.tutorials.title)
    console.log('this.tutorials.chargeCoin :::'+this.tutorials.chargeCoin)
    if (this.file) {
      const formData = new FormData()
      formData.append('file', this.file)
      formData.append('originalFilename', this.file.name)
      this.serviceUpload.addImage(formData).subscribe(el => {
        console.log('el =', el)
        this.imgURL = el.avatarUrl
        this.tutorials.thumbnail = this.imgURL
        if (this.tutorials.id === undefined ) {
          this.subscribeToSaveResponse(this.tutorialsService.create(this.tutorials))
        } else {
          // console.log("update update update update update update");
          this.subscribeToSaveResponse(this.tutorialsService.update(this.tutorials))
        }
      })
    } else {
      this.subscribeToSaveResponse(this.tutorialsService.update(this.tutorials))
    } }

  protected subscribeToSaveResponse(result: Observable<HttpResponse<ITutorials>>) {
    result.subscribe((res: HttpResponse<ITutorials>) => this.onSaveSuccess(), (res: HttpErrorResponse) => { })
  }
  protected onSaveSuccess() {
    this.previousState()
  }
  previousState() {
    window.history.back()
  }
  onSelectFile(event) {
    const files = event.target.files
    if (files.length === 0) {
      return
    }

    const mimeType = files[0].type
    if (mimeType.match(/image\/*/) == null) {
      // this.message = "Only images are supported.";
      return
    }

    const reader = new FileReader()
    this.file = files[0]
    reader.readAsDataURL(files[0])
    // tslint:disable-next-line:variable-name
    reader.onload = (_event) => {
      this.imgURL = reader.result
      console.log('imgUrl onSelectFile():::', this.imgURL)
    }
  }
  onRemoveImage() {
    this.imgURL = null
    console.log('imgUrl', this.imgURL)
  }
  onUploadImage() {
    const formData = new FormData()
    formData.append('file', this.file)
    formData.append('originalFilename', this.file.name)
    this.serviceUpload.addImage(formData).subscribe(el => {
      console.log('el =', el)
      this.imgURL = el.avatarUrl
      //   this.data = el.avatarUrl;
      //   this.passEntry.emit(this.data);
      //  this.activeModal.dismiss('cancel');
    })}

    getCategoryList() {
      this.categoryService.getListCategory().subscribe(
        (result)=> {
          if (result && result.body) {
            this.categorys = result.body
            this.isShow()
          }
          console.log('tutorial getCategoryList() :::' , this.categoryList)

        },
      )
    }
    isShow() {
      // this.show = !this.show
      this.numberSelect = this.categoryList.length
      this.categorys.forEach(element => {
        for (let i = 0; i < this.categoryList.length; i++) {
          if(element.id === this.categoryList[i].id) {
            console.log('isShow() element:::', element)
            element.isChecked = true
            console.log('element.isChecked =',element.isChecked )
          }
        }})
    }
    selectCategory() {
      this.show = !this.show
    }
    onSelect(event) {
      console.log('onSelect() EVENT :::',event)
      console.log('onSelect() :::',event.path[0].id)

      if (event.path[0].checked) {
        this.numberSelect++
        this.categoryList.push({id: event.path[0].id, name: event.path[0].defaultValue})
        console.log('onSelect() :::',event.path[0].checked)
        console.log('Array :::' , this.categoryList)
      }
      else {
        this.numberSelect--
        this.categorys.forEach(element => {
          if(element.id === event.path[0].id) {
            element.isChecked = false
          }
        })
        const index = this.categoryList.findIndex(category => category.id === event.path[0].id)
        this.categoryList.splice(index, 1)
        console.log('Array :::' , this.categoryList)

      }

    }
    remove(category: Catergory): void {
      const index = this.categoryList.indexOf(category)

      if (index >= 0) {
        this.categoryList.splice(index, 1)
      }
      console.log('Array :::' , this.categoryList)
    }
    changePrice(price) {
      // console.log("price ::::",price);
      this.tutorials.chargeCoin = price
      console.log('this.tutorials.chargeCoin =',this.tutorials.chargeCoin)

    }
    onClick(value) {
      // console.log("onClicl()  :::",value);
      if(value === 'free') {
        this.disable = false
        this.tutorials.chargeCoin = 0
        console.log('this.tutorials.chargeCoin =',this.tutorials.chargeCoin)
      } else if(value === 'price_free') {
        this.disable = true
        this.tutorials.chargeCoin = this.editForm.get(['price']).value
      }
      console.log('this.tutorials.chargeCoin =',this.tutorials.chargeCoin)
    }

    onChangeStatus(event, status) {
      this.tutorials.activated = status.code
    }
}
