import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { AppState } from '../../../reducers/index'
import { selectUnreadGlobalCountById } from '../../../unread/store/unread-feature.selectors'
import { imageAvatarFallback } from '../../../utils/string.util'


@Component({
  selector: 'app-moderator-direct-receiver',
  templateUrl: './moderator-direct-receiver.component.html',
  styleUrls: ['./moderator-direct-receiver.component.scss'],
})
export class ModeratorDirectReceiverComponent implements OnInit, OnDestroy {

  constructor(
    private store: Store<AppState>,
  ) { }

  destroy$: Subject<boolean> = new Subject<boolean>()

  @Input()
  chatId

  @Input()
  participant

  @Input()
  active

  unreadCount$

  ngOnInit() {
    this.unreadCount$ = this.store.pipe(
      select(selectUnreadGlobalCountById, { chatId: this.chatId} ),
      takeUntil(this.destroy$),
    )
  }

  avatarLinkHandler(url) {
    return imageAvatarFallback(url)
  }

  ngOnDestroy() {
    this.destroy$.next(true)
  }

}
