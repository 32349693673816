import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { AppState } from '../reducers'
import { PunishmentNotificationType, PunishmentReason } from '../timeline/models/Notification.interface'
import { fetchNotifications } from '../timeline/timeline-feature.actions'
import { TimelineService } from '../timeline/timeline.service'
import { setActiveTab } from '../../app/timeline/timeline-feature.actions'
import { TournamentScreenTab } from '../timeline/models/TournamentScreenTab.enum'

@Component({
  selector: 'app-timeline-notification-item',
  templateUrl: './timeline-notification-item.component.html',
  styleUrls: ['./timeline-notification-item.component.scss'],
})
export class TimelineNotificationItemComponent implements  OnDestroy {

  constructor(
    private timelineService: TimelineService,
    private store: Store<AppState>,
  ) { }

  destroy$: Subject<boolean> = new Subject<boolean>()

  @Input()
  notification

  @Input()
  index

  dropdownOpened = true

  onIndexClick() {
    this.store.dispatch(setActiveTab({ tab: TournamentScreenTab.Timeline }))
  }

  toggleOpen() {
    this.dropdownOpened = !this.dropdownOpened
  }

  onCancel() {
    this.timelineService.cancelNotification({ notificationId: this.notification.id})
    .pipe(takeUntil(this.destroy$)).subscribe(
      data => {
        this.store.dispatch(fetchNotifications())
        console.log('canceled!')
      },
    )
  }

  onPermit() {
    this.timelineService.permitNotification( { notificationId: this.notification.id })
      .pipe(takeUntil(this.destroy$)).subscribe(
      data => {
        this.store.dispatch(fetchNotifications())
        console.log('permitted')
      },
    )
  }

  getTextByType(type: PunishmentNotificationType) {

    switch (type) {
      case PunishmentNotificationType.RED_CARD:
        return 'Red card'
      case PunishmentNotificationType.YELLOW_CARD:
        return 'Yellow card'
      default:
        return ''
    }
  }

  getTextByReason(reason: PunishmentReason) {

    switch (reason) {
      case PunishmentReason.BOTH_DID_NOT_ENTER_SCORE:
        return 'Both did not entered score'
      case PunishmentReason.BOTH_DID_NOT_PRESS_START:
        return 'Both did not pressed start'
      case PunishmentReason.ONE_DID_NOT_ENTER_SCORE:
        return 'One did not enter score'
      case PunishmentReason.ONE_DID_NOT_PRESS_FINISH:
        return 'One did not pressed finish'
      case PunishmentReason.ONE_DID_NOT_PRESS_START:
        return 'One did not pressed start'
      default:
        return ''
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
  }

}
