import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse,} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AppConstants} from '../app.constants'

@Injectable({ providedIn: 'root' })

export class CategoryService{
  constructor(
    private http : HttpClient
  ){}
  getListCategory(): Observable<HttpResponse<any>>{
    return this.http.get(AppConstants.SERVER_API_URL + '/api/tutorial-categories' ,  { observe: 'response' });
  }
}