import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { AppConstants } from '../app.constants'

@Injectable({ providedIn: 'root' })
export class TournamentService {

    constructor(
        private http: HttpClient,
    ) { }

    getTournamentDetail(tournamentId: any): Observable<HttpResponse<any>> {
        return this.http.get(AppConstants.SERVER_API_URL + `/api/tournaments/${tournamentId}`, { observe: 'response' })
    }

    getStagesByTour(tournamentId: string): Observable<any> {
        return this.http.get(`${AppConstants.SERVER_API_URL}/api/bracket/stage-names/${tournamentId}`, { observe: 'body'})
    }

    getRoomsByStages(stages: string[], tournamentId: string, withMatches = false, isHeadOrSuperModerator = false): Observable<any> {
        const body = stages
        const withMatchesParam = withMatches ? '&withMatches=true' : ''

        const roomsScope = isHeadOrSuperModerator ? 'all' : 'my'

        return this.http
        .post(`${AppConstants.SERVER_API_URL}/api/moderator-room/${roomsScope}-rooms?tournamentId=${tournamentId}${withMatchesParam}`,
        body, { observe: 'body'} )
    }

    getSectorList(tournamentId: any): Observable<HttpResponse<any>> {
        const params = new HttpParams()
            .set('tournamentId', tournamentId)
        return this.http.get(AppConstants.SERVER_API_URL + `/api/tournaments/getSectorList`, { params, observe: 'response' })
    }

    getStageList(tournamentId: any, sectorIndex: any): Observable<HttpResponse<any>> {
        const params = new HttpParams()
            .set('tournamentId', tournamentId)
            .set('sectorIndex', sectorIndex)

        return this.http.get(AppConstants.SERVER_API_URL + `/api/tournaments/getStageList`, { params, observe: 'response' })
    }

    getTourMatchList(tournamentId: any, sectorIndex: any, stageIndex: any): Observable<HttpResponse<any>> {
        const params = new HttpParams()
            .set('tournamentId', tournamentId)
            .set('sectorIndex', sectorIndex)
            .set('stageIndex', stageIndex)

        return this.http.get(AppConstants.SERVER_API_URL + `/api/tournaments/getTourMatchList`, { params, observe: 'response' })
    }


    // tslint:disable-next-line:max-line-length
    cmsGetAllModeratorByTournament(tournamentId: any, page: any = 0, size: any = 20, name?: any, status?: any): Observable<HttpResponse<any>> {
        const params = new HttpParams()
            .set('tournamentId', tournamentId)
            .set('page', page)
            .set('size', size)
            .set('name', name || '')
            .set('status', status || '')
        return this.http.get(AppConstants.SERVER_API_URL + '/api/tournament-moderators/cmsGetAllModeratorByTournamentWithPaging', {
            params,
            observe: 'response',
        })
    }
    // PUT /api/tournaments/{id}/start
    startTournament(tournamentId: any): Observable<HttpResponse<any>> {
        return this.http.put(AppConstants.SERVER_API_URL + `/api/tournaments/${tournamentId}/start`, null, { observe: 'response' })
    }
    // PUT /api/tournaments/{id}/pause

    pauseTournament(tournamentId: any, isPause: boolean): Observable<HttpResponse<any>> {
        const payload = {
            pause: isPause,
        }
        return this.http.put(AppConstants.SERVER_API_URL + `/api/tournaments/${tournamentId}/pause`, payload, { observe: 'response' })
    }

    // @PutMapping("/tournaments/{id}/nextRound")

    startNextRoundTournament(tournamentId: any): Observable<HttpResponse<any>> {

        return this.http.put(AppConstants.SERVER_API_URL + `/api/tournaments/${tournamentId}/nextRound`, null, { observe: 'response' })
    }

    checkInManually(tournamentId: any, userId: any): Observable<HttpResponse<any>> {

        return this.http.post(AppConstants.SERVER_API_URL + `/api/tournaments/${tournamentId}/checkIn?userId=${userId}`, null, { observe: 'response' })
    }


    unCheckIn(tournamentId: any, userId: any): Observable<HttpResponse<any>> {
        return this.http.post(AppConstants.SERVER_API_URL +
            `/api/tournaments/${tournamentId}/uncheckIn?userId=${userId}`,
            null,
            { observe: 'response' },
        )
    }


    unJoin(tournamentId: string, userId: string) {
        return this.http.post(
            `${AppConstants.SERVER_API_URL}/api/tournaments/${tournamentId}/unJoin?userId=${userId}`,
            null,
            { observe: 'body'},
       )
    }

}
