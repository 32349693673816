import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router'
import { Store } from '@ngrx/store'
import { AppState } from '../reducers'
import { AccountService } from './account.service'
import { setUserId } from '../actions/chatbox.actions'
import { setAccountUserId } from './account.actions';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private accountService: AccountService,
    private store: Store<AppState>,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> {
    console.log('AuthGuard canActivate()------>>>>')

    const authorities = route.data.authorities



    console.log('AuthGuard authorities::::', authorities)
    console.log('AuthGuard state.url::::', state.url)

    // We need to call the checkLogin / and so the accountService.identity() function, to ensure,
    // that the client has a principal too, if they already logged in by the server.
    // This could happen on a page refresh.
    const isCheck  =  this.checkLogin(authorities, state.url)

    console.log('AuthGuard isCheck::::', isCheck)

    return isCheck


  }

  checkLogin(authorities: string[], url: string): Promise<boolean> {

    console.log('AuthGuard checkLogin()------>>>>')

    return this.accountService.identity().then(account => {
      console.log('Account: ', account)

      
      if (!account || !account.id) {
        this.router.navigate(['/login'])
      }

      this.store.dispatch(setAccountUserId({ userId: account.id}))
      this.store.dispatch(setUserId({ userId: account.id}))

      if (!authorities || authorities.length === 0) {
        return true
      }

      if (account) {
        const hasAnyAuthority = this.accountService.hasAnyAuthority(authorities)
        if (hasAnyAuthority) {
          return true
        }
        return false
      }


      return false

      // this.stateStorageService.storeUrl(url);

      // this.router.navigate(['accessdenied']).then(() => {
      //   // only show the login dialog, if the user hasn't logged in yet
      //   if (!account) {
      //     this.loginModalService.open();
      //   }
      // });
   
    })
  }
}
