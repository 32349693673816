import { Action, createReducer, on } from '@ngrx/store'
import { PaginatorState } from '../../shared/model/paginatorState.model'
import { Faq } from '../models/faq.model'
import * as FaqFeatureActions from './faq-feature.actions'

export const faqFeatureFeatureKey = 'faqFeature'

export interface FaqState {

  faqList: {
    [key: string]: Faq,
  }
  faqListLength: number,
  faqListSearch: string,
  faqPaginator: PaginatorState,

}

export const initialState: FaqState = {

  faqList: {},
  faqListLength: undefined,
  faqListSearch: undefined,
  faqPaginator: {
    size: 5,
    page: 0,
    opts: [5, 10, 15],
  },
}

const faqFeatureReducer = createReducer(
  initialState,

  on(FaqFeatureActions.setFaqPaginator, ( state, { state: { size, page }}  ) => {
      return {
        ...state,
        faqPaginator: {
          ...state.faqPaginator, size, page,
        },
      }
  }),

  on(FaqFeatureActions.loadFaqsSuccess, ( state, { data, faqListLength } ) => {
    const faqList = {}
    data.map(faq => faqList[faq.id] = faq)

    return { ...state, faqList, faqListLength }
  }),

)

export function reducer(state: FaqState | undefined, action: Action) {
  return faqFeatureReducer(state, action)
}
