import { Component, OnDestroy, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import * as _ from 'lodash'
import { max } from 'lodash'
import { Observable, Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { AppState } from '../../../../reducers/index'
import { selectNormalized } from '../../../../selectors/chatbox.selectors'
import { selectAllTournamentsNames } from '../../../../tournament-data/store/tournament-data.selectors'
import { MarkAsReadDTO } from '../../../../unread/models/mark-as-read.model'
import { markAsReadMessagesInfo, setOpen, setActiveTab } from '../../../../actions/chatbox.actions';
import { Router } from '@angular/router';
import { ChatboxTabs } from '../../../../chatbox/models/ChatboxTabs.enum';

@Component({
  selector: 'app-ajax-notifier',
  templateUrl: './ajax-notifier.component.html',
  styleUrls: ['./ajax-notifier.component.scss'],
})
export class AjaxNotifierComponent implements OnInit, OnDestroy {

  notifierShown = false
  destroy$: Subject<boolean> = new Subject<boolean>()
  total = 7
  tournamentNames: {[key: string]: { name: string}}


  normalized: any

  onShow() {
    this.notifierShown = true
  }

  onHide() {
    this.notifierShown = false
  }

  onPrivateMessageRedirect() {

    const id = max(Object.keys(this.tournamentNames))

    this.router.navigate(['/dashboard/tournament', id, 'timeline']).then(
      () => {
        this.store.dispatch(setOpen({ open: true }))
        this.store.dispatch(setActiveTab({ activeTab: ChatboxTabs.TM }))
      })

  }

  ngOnInit() {
    this.store.select(selectAllTournamentsNames).pipe(
      takeUntil(this.destroy$),
    ).subscribe( val => this.tournamentNames = val)

    this.store.select(selectNormalized).pipe(
      takeUntil(this.destroy$),
    ).subscribe(({ moderatorDirectUnreadData,
      supportNormalizedData,
      moderatorTournamentData,
      moderatorGlobalData }) => {

      const total: TotalUnread = {
        total: 0,
        tournaments: {},
        directModerator: {
          total: 0,
          data: [],
        },
      }

      for (const chatId in moderatorGlobalData) {
        if (moderatorGlobalData.hasOwnProperty(chatId)) {
          const element = moderatorGlobalData[chatId]

          const data = _.flatten(Object.values(element)) || chatUnreadInitial.data

          total.total += data.length
          total.directModerator.total += data.length
          total.directModerator.data = [...data]

        }
      }


      for (const tournamentId in supportNormalizedData) {
        if (supportNormalizedData.hasOwnProperty(tournamentId)) {
          const element = supportNormalizedData[tournamentId]
          const data = _.flatten(Object.values(element)) || chatUnreadInitial.data

          total.total += data.length
          const updated =  {
             ...element,
            reports: {
              data,
              total: data.length,
            },
          }

          total.tournaments[tournamentId] = {
            ...total.tournaments[tournamentId],
            ...updated,
          }
        }
      }

      for (const tournamentId in moderatorDirectUnreadData) {
        if (moderatorDirectUnreadData.hasOwnProperty(tournamentId)) {
          const element = moderatorDirectUnreadData[tournamentId]
          const data = _.flatten(Object.values(element)) || chatUnreadInitial.data

          total.total += data.length

          const updated =  {
             ...element,
            direct: {
              data,
              total: data.length,
            },
          }

          total.tournaments[tournamentId] = {
            ...total.tournaments[tournamentId],
            ...updated,
          }

        }
      }

      for (const tournamentId in moderatorTournamentData) {
        if (moderatorTournamentData.hasOwnProperty(tournamentId)) {
          const element = moderatorTournamentData[tournamentId]
          const data = _.flatten(Object.values(element)) || chatUnreadInitial.data

          total.total += data.length

          const updated =  {
             ...element,
            tm: {
              data,
              total: data.length,
            },
          }

          total.tournaments[tournamentId] = {
            ...total.tournaments[tournamentId],
            ...updated,
          }

        }
      }

      this.normalized = {...total}
      // console.log('[Notifier]', this.normalized)

    })

  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
  }

  isEmpty(obj: any): boolean {
    return _.isEmpty(obj)
  }

  constructor(
    private store: Store<AppState>,
    private router: Router,
  ) { }

  markAsRead(messages: any[]) {
    const dto = messages.map(({ messageInfoKey }) => messageInfoKey)
    this.store.dispatch(markAsReadMessagesInfo({ dto }))
  }

}
export interface TotalUnread {
  total: number
  tournaments: {
    [key: string]: TournamentUnread,
  }
  directModerator: ChatUnread,
}

export interface TournamentUnread {
  total: number
  reports: ChatUnread
  direct: ChatUnread
  tm: ChatUnread

}

export interface ChatUnread {
  total: number
  data: MarkAsReadDTO[]
}

export const chatUnreadInitial = {
  total: 0,
  data: [],
}

export const sortByKey = ( object ) => _.sortBy(object)
