import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { markAsReadMessagesInfo, setOpen, setActiveTab } from '../../../../actions/chatbox.actions'
import { ChatboxTabs } from '../../../../chatbox/models/ChatboxTabs.enum'
import { AppState } from '../../../../reducers/index'
import { TournamentUnread } from '../ajax-notifier/ajax-notifier.component'

@Component({
  selector: 'app-notify-list-item',
  templateUrl: './notify-list-item.component.html',
  styleUrls: ['./notify-list-item.component.scss'],
})
export class NotifyListItemComponent implements OnInit, OnDestroy {

  @Input()
  tournament: TournamentUnread

  @Input()
  id: string

  @Input()
  name: string

  @Input()
  type: ChatboxTabs

  chatboxTabs = ChatboxTabs

  markAsRead( messages: any[]) {
    const dto = messages.map( ({ messageInfoKey }) => messageInfoKey )
    this.store.dispatch(markAsReadMessagesInfo({ dto }))
  }

  onOpen(evt, activeTab: ChatboxTabs) {

    console.log(evt)

    this.router.navigate(['/dashboard/tournament', this.id, 'timeline']).then(
      () => {
          this.store.dispatch(setOpen({ open: true }))
          this.store.dispatch(setActiveTab({ activeTab }))
    })

  }
    // if (this.data.actionData) {
      // const { tournamentId, chatId, chatType } = this.data.actionData

      // if (chatType === ChatboxTabs.TM) {
      //   this.router.navigate(['/dashboard/tournament', tournamentId, 'timeline']).then(() => {
      //     this.store.dispatch(ChatboxActions.setOpen({ open: true }))
      //     this.store.dispatch(ChatboxActions.setActiveTab({ activeTab: chatType }))
      //     this.store.dispatch(ChatboxActions.onModeratorDirectTournamentSetActiveId({ activeId: chatId }))
      //     this.store.dispatch(ChatboxActions.markAsReadMessagesInfoByChatId({ chatId }))
      //   })
      // }

      // if (chatType === ChatboxTabs.Reports) {
      //   this.router.navigate(['/dashboard/tournament', tournamentId, 'timeline']).then(() => {
      //     this.store.dispatch(ChatboxActions.setOpen({ open: true }))
      //     this.store.dispatch(ChatboxActions.setActiveTab({ activeTab: chatType }))

      //     this.store.dispatch(ChatboxActions.reportsSetActiveId({ chatId }))
      //     this.store.dispatch(ChatboxActions.markAsReadSupportByChatId({ chatId }))
      //   })
      // }

      // if (chatType === ChatboxTabs.Direct) {
      //   this.router.navigate(['/dashboard/tournament', tournamentId, 'timeline']).then(() => {
      //     this.store.dispatch(ChatboxActions.setOpen({ open: true }))
      //     this.store.dispatch(ChatboxActions.setActiveTab({ activeTab: chatType }))


      //     this.store.dispatch(onModeratorDirectTournamentSetActiveId({ activeId: chatId }))
      //     this.store.dispatch(markAsReadMessagesInfoByChatId({ chatId }))
      //   })
      // }
    // }


  constructor(
    private store: Store<AppState>,
    private router: Router,
  ) { }

  ngOnInit() {
  }

  ngOnDestroy(): void {
  }
}
