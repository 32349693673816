import { Component, Input, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { AppState } from 'src/app/reducers'
import { selectTmMessagesUnread } from '../../../selectors/chatbox.selectors'
import { selectTournamentData } from '../../../timeline/timeline-feature.selectors'

@Component({
  selector: 'app-tournament-moderators-chat-room',
  templateUrl: './tournament-moderators-chat-room.component.html',
  styleUrls: ['./tournament-moderators-chat-room.component.scss'],
})
export class TournamentModeratorsChatRoomComponent implements OnInit {

  constructor(
    private store: Store<AppState>,
  ) { }

  @Input()
  filter

  roomUnreadCount: Observable<number>

  tournament

  @Input()
  active

  ngOnInit() {
    this.tournament = this.store.select(selectTournamentData)
    this.roomUnreadCount = this.store.select(selectTmMessagesUnread)
  }

}
