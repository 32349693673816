import { MatPaginatorIntl } from '@angular/material'

export class MatPaginatorIntlUsersList extends MatPaginatorIntl {
    itemsPerPageLabel = 'Users per page'
}

export class MatPaginatorIntlTournamentsList extends MatPaginatorIntl {
    itemsPerPageLabel = 'Tournaments per page'
}

export class MatPaginatorIntlTutorialsList extends MatPaginatorIntl {
    itemsPerPageLabel = 'Tutorials per page'
}

export class MatPaginatorIntlModeratorsList extends MatPaginatorIntl {
    itemsPerPageLabel = 'Moderators per page'
}

export class MatPaginatorIntlFAQList extends MatPaginatorIntl {
    itemsPerPageLabel = 'FAQ items per page'
}

export class MatPaginatorIntlNewsList extends MatPaginatorIntl {
    itemsPerPageLabel = 'News per page'
}

export class MatPaginatorIntlQuizList extends MatPaginatorIntl {
    itemsPerPageLabel = 'Quiz questions per page'
}