export const TOURNAMENT_FORMAT_ARR = [
    {
        id: 1,
        code: 'knock_out',
        name: 'Knock-Out',
        isChecked: true,
    },
]

export const PARTICIPANT_NUMBER_ARR = [
    {
        id: 4,
        name: '4',
    },
    {
        id: 8,
        name: '8',
    },
    {
        id: 16,
        name: '16',
    },
    {
        id: 32,
        name: '32',
    },
    {
        id: 64,
        name: '64',
    },
    {
        id: 128,
        name: '128',
    },
    {
        id: 256,
        name: '256',
    },
    {
        id: 512,
        name: '512',
    },
]
export const ARRAY_CONSOLE = [
    {
        id: 1,
        name: 'Playstation',
        code: 'ps',
        src: './assets/images/pss.png',
        isChecked: true,
    },
    {
        id: 2,
        name: 'Xbox',
        code: 'xbox',
        src: './assets/images/xboxx.png',
        isChecked: false,
    },
]

export const CARD_TYPE = {
    RED: 2,
    YELLOW: 1,
}

export const DATE_FORMAT = 'YYYY-MM-DD'
export const DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm'
export const TOURNAMENT_STATUS_LIVE_NUMBER = 1
export const TOURNAMENT_STATUS_FINISH_NUMBER = 2
