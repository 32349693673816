import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { HttpClient } from '@angular/common/http'
import { BehaviorSubject, Observable } from 'rxjs'

import { AppConstants } from '../app.constants'
import { map } from 'rxjs/operators'

@Injectable({ providedIn: 'root' })
export class JwtAuthService {

    constructor(
        private http: HttpClient,
    ) { }

    getToken(): any {
        return localStorage.get('authenticationToken') || sessionStorage.get('authenticationToken')
    }

    login(payload): Observable<any> {
        console.log('JwtAuthService login payload:::', payload)

        const data = {
            userId: payload.userId,
            loginVerificationCode: payload.loginVerificationCode,
        }
        return this.http.post(AppConstants.SERVER_API_URL + '/api/authenticateSecondStep', data, { observe: 'response' }).pipe(map(authenticateSuccess.bind(this)))

        function authenticateSuccess(resp) {
            console.log('JwtAuthService authenticateSuccess resp:::', resp)
            const requestBodyStatus = resp.body.status

            if(requestBodyStatus === 400) {
                return resp.body
            } else if(requestBodyStatus == 200) {
                const bearerToken = resp.headers.get('Authorization')
                if (bearerToken && bearerToken.slice(0, 7) === 'Bearer ') {
                    const jwt = bearerToken.slice(7, bearerToken.length)
                    this.storeAuthenticationToken(jwt, true)
                    return resp.body
                }
            }



        }
    }

    loginWithToken(jwt, rememberMe) {
        if (jwt) {
            this.storeAuthenticationToken(jwt, rememberMe)
            return Promise.resolve(jwt)
        } else {
            return Promise.reject('auth-jwt-service Promise reject') // Put appropriate error message here
        }
    }

    storeAuthenticationToken(jwt, rememberMe) {
        console.log('JwtAuthService storeAuthenticationToken jwt:::', jwt)

        if (rememberMe) {
            localStorage.setItem('authenticationToken', jwt)
        } else {
            sessionStorage.setItem('authenticationToken', jwt)
        }

        console.log('JwtAuthService localStorage:::', localStorage)
        console.log('JwtAuthService sessionStorage:::', sessionStorage)

    }

    logout(): void {
        console.log('JwtAuthService logout....')

        localStorage.removeItem('authenticationToken')
        sessionStorage.removeItem('authenticationToken')
    }
}
