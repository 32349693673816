import { Action, createReducer, on } from '@ngrx/store'
import * as AccountActions from './account.actions'

export const accountFeatureKey = 'account'

export interface AccountState {

  userId: string
  authorities: string[]
  user: any

}

export const initialState: AccountState = {

  userId: undefined,
  authorities: [],
  user: {},

}

const accountReducer = createReducer(
  initialState,

  on(AccountActions.fetchUserSuccess, ( state, { user }) => ({...state, user })),

  on(AccountActions.setAccountUserId, (state, { userId }) => ({ ...state, userId}) ),

  on(AccountActions.setAuthorities, (state, {authorities}) => ({...state, authorities}) ),
  on(AccountActions.dropAuthorities, state => ({...state, authorities: initialState.authorities })),

)

export function reducer(state: AccountState | undefined, action: Action) {
  return accountReducer(state, action)
}
