import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { AppConstants } from '../app.constants'

@Injectable({ providedIn: 'root' })
export class ModeratorsService {

    constructor(
        private http: HttpClient,
    ) { }

    public checkEmailExists(email: string) {
        return this.http.get(
            `${AppConstants.SERVER_API_URL}/api/account/checkEmailExisting?email=${email}`
        )
    }
    delete(moderatorId: string) {
        return this.http.delete(
            `${AppConstants.SERVER_API_URL}/api/moderator-rest/moderator?moderatorId=${moderatorId}`
        )
    }


    register(dto) {
        return this.http.post(
            `${AppConstants.SERVER_API_URL}/api/moderator-rest/moderator`,
            dto,
            {
                observe: 'body',
            },
        )
    }

    edit( dto ) {
        return this.http.put(
            `${AppConstants.SERVER_API_URL}/api/moderator-rest/moderator`,
            dto, { observe: 'body' },
        )
    }



    getModeratorList(page: number, size: number, status: any, moreFilters: any, name = ''): Observable<HttpResponse<any>> {

        const filter = {
            status, moreFilters,
        }

        return this.http.post(AppConstants.SERVER_API_URL +
            '/api/moderator-rest/getAllModerators?page='+page+'&size='+size+'&name='+name, filter, { observe: 'response' })
    }


    inviteModerator(email: string): Observable<HttpResponse<any>> {
        const payload = {
            email,
        }

        return this.http.put(AppConstants.SERVER_API_URL + '/api/moderator-rest/inviteModerator', payload, { observe: 'response' })
    }


    getUpcomingTournamentList(page: number, size: number): Observable<HttpResponse<any>> {

        return this.http.get(AppConstants.SERVER_API_URL +
            '/api/tournaments/getUpcommingTournaments?page='+page+'&size='+size, { observe: 'response' })
    }



    // /api/moderator-rest/addTournamentModerator
    assignModeratorToTournament(moderatorId: number, tournamentId: number): Observable<HttpResponse<any>> {

        // {
        // "tournamentId": 3,
        // "moderatorId": 5
        // }

        const payload = {
            tournamentId,
            moderatorId,
        }

        return this.http.post(AppConstants.SERVER_API_URL +
            '/api/moderator-rest/addTournamentModerator', payload, { observe: 'response' })
    }

    assignModeratorToMatch(
        moderatorId: number, tournamentId: number,
        tourMatchId: number, sectorIndex: string,
        stageIndex: string): Observable<HttpResponse<any>> {

        // {
        // "tournamentId": 3,
        // "tourMatchId": 4,
        // "moderatorId": 5
        // }

        const payload = {
            tournamentId,
            tourMatchId,
            moderatorId,
            sectorIndex,
            stageIndex,
        }

        return this.http.post(AppConstants.SERVER_API_URL + '/api/moderator-rest/assignMatchModerator', payload, { observe: 'response' })
    }

    getModeratorDetailInfo(moderatorId: string): Observable<HttpResponse<any>> {

        return this.http.get(AppConstants.SERVER_API_URL + `/api/moderator-rest/getModeratorInfo/${moderatorId}`, { observe: 'response' })
    }

    cmsModeratorGetAgenda(moderatorId: any, month: any, year: any): Observable<HttpResponse<any>> {
        const params = new HttpParams()
            .set('moderatorId', moderatorId)
            .set('month', month)
            .set('year', year)
        return this.http.get(AppConstants.SERVER_API_URL + '/api/tournament-moderators/cmsModeratorGetAgenda', {
            params,
            observe: 'response',
        })
    }
  }
