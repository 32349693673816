import * as Stomp from 'stompjs'
import * as SockJS from 'sockjs-client'
import { Subject, Observable } from 'rxjs'
import { AppConstants } from 'src/app/app.constants';

export class WebSocketAPI {
    webSocketEndPoint: string = AppConstants.SERVER_API_URL+'/socket'
    topic: any[]
    stompClient: any
    private recieveDataSubject = new Subject<any>()



    constructor(topic: any[]) {
        this.topic = topic
    }

    _connect() {
        console.log('WebSocketAPI Initialize WebSocket Connection')
        console.log('webSocketEndPoint:::', this.webSocketEndPoint)
        console.log('topic:::', this.topic)

        const ws = new SockJS(this.webSocketEndPoint)
        this.stompClient = Stomp.over(ws)
        const _this = this
        _this.stompClient.connect({}, function(frame) {
            _this.topic.forEach((item)=> {

                console.log('[WS]: connecting to ', item)
                _this.stompClient.subscribe(item.path, function(sdkEvent) {

                    _this.onMessageReceived(item.name, sdkEvent)
                })
            })

            // _this.stompClient.reconnect_delay = 2000;
        }, this.errorCallBack)
    }

    _disconnect() {
        if (this.stompClient !== null) {
            this.stompClient.disconnect()
        }
        if(this.recieveDataSubject !== null) {
            this.recieveDataSubject.unsubscribe()
        }
        console.log('Disconnected')
    }

    // on error, schedule a reconnection attempt
    errorCallBack(error) {
        console.log('errorCallBack -> ' + error)
        setTimeout(() => {
            this._connect()
        }, 5000)
    }

 /**
  * Send message to sever via web socket
  * @param {*} message
  */
    _send(endpoint, message) {
        console.log('calling logout api via web socket')
        this.stompClient.send(endpoint, {}, JSON.stringify(message))
    }

    private onMessageReceived(name: string, message: any) {
        console.log('WebSocketAPI onMessageReceived()....')

        this.recieveDataSubject.next({ name, data: message })
    }

    clearMessages() {
        this.recieveDataSubject.next()
    }

    getRecieveWebSocketData(): Observable<any> {
        return this.recieveDataSubject.asObservable()
    }
}
