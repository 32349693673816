import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Component, OnInit, Inject } from '@angular/core'
import { MatDialogRef } from '@angular/material'

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
})
export class ConfirmComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmModalData,
  ) { }

  ngOnInit() {
  }

  onClickYes(): void {
    this.dialogRef.close(true)
  }

  onClickNo(): void {
    this.dialogRef.close(false)
  }



}

export interface ConfirmModalData {
  title?: string
  content?: string
}
