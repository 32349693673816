import { Action, createReducer, on } from '@ngrx/store'
import { PaginatorState } from '../../shared/model/paginatorState.model'
import { TournamentShort } from '../models/tournamentShort.interface'
import { extractTournamentShort } from '../utils/tournament-data.utils'
import * as TournamentDataActions from './tournament-data.actions'

export const tournamentDataFeatureKey = 'tournamentData'

export interface TournamentDataState {

  tournaments: {
    [key: string]: TournamentShort,
  }
  tournamentsIsLoading: boolean,
  tournamentsLoaded: boolean,

  openedTournamentId: string,

  moderators: {
    [key: string]: any,
  }
  moderatorsListLength: number,
  moderatorsListSearch: string,
  moderatorsPaginator: PaginatorState

}

export const initialState: TournamentDataState = {

  tournaments: {},
  tournamentsIsLoading: false,
  tournamentsLoaded: false,

  openedTournamentId: undefined,

  moderators: {},
  moderatorsListLength: 0,
  moderatorsListSearch: undefined,
  moderatorsPaginator: {
    size: 5,
    page: 0,
    opts: [5, 10],
  },
}

const tournamentDataReducer = createReducer(
  initialState,

  on(TournamentDataActions.setOpenedTournamentId, (state, { openedTournamentId }) => ({ ...state, openedTournamentId })),

  on(TournamentDataActions.fetchTournaments, state => ({...state, tournamentsIsLoading: true} )),
  on(TournamentDataActions.fetchTournamentsSuccess, (state, { data }) => {
    const tournaments = {}
    data.map(tournament => tournaments[tournament.id] = extractTournamentShort(tournament))
    return { ...state, tournaments, tournamentsLoaded: true }
  }),
  on(TournamentDataActions.fetchTournamentsFailure, (state, action) => ({...state, tournamentsIsLoading: false})),


  on(TournamentDataActions.setModeratorsPaginator, (state, { state: { size, page } }) => {
    return {
      ...state,
      moderatorsPaginator: {
        ...state.moderatorsPaginator, size, page,
      },
    }
  }),

on(TournamentDataActions.fetchModeratorsSuccess, ( state, { data: { moderators }, length } ) => {
    const moderatorsList = {}
    moderators.map( m => moderatorsList[m.id] = m )

    return { ...state, moderators: moderatorsList, moderatorsListLength: length  }
}),

on(TournamentDataActions.setModeratorsSearch, (state, { name }) =>
 ({ ...state, moderatorsListSearch: name, moderatorsPaginator: initialState.moderatorsPaginator })),



)

export function reducer(state: TournamentDataState | undefined, action: Action) {
  return tournamentDataReducer(state, action)
}


