import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { QuizQuestion } from '../../models/quiz.model';

@Component({
  selector: 'app-add-question-modal',
  templateUrl: './add-question-modal.component.html',
  styleUrls: ['./add-question-modal.component.scss'],
})
export class AddQuestionModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<AddQuestionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddQuestionModalData,
    private formBuilder: FormBuilder,
  ) { }

  quizQuestionForm: FormGroup
  id

  ngOnInit() {

    const { question: {
      imageUrl = '', question = '', isPublic = false, id, quizAnswers = [
        {answer: '', isCorrect: true},
        {answer: '', isCorrect: false},
        {answer: '', isCorrect: false},
      ],
    } } = this.data

    if ( id ) {
      this.id = id
    }

    this.quizQuestionForm = this.formBuilder.group({
      imageUrl: [imageUrl],
      question: [question, [ Validators.required, Validators.minLength(4) ] ],
      isPublic: [ isPublic.toString(), [ Validators.required ] ],
      q1: [ quizAnswers[0].answer, [Validators.required, Validators.minLength(2)]],
      q2: [ quizAnswers[1].answer, [Validators.required, Validators.minLength(2)]],
      q3: [ quizAnswers[2].answer, [Validators.required, Validators.minLength(2)]],

      r1: [quizAnswers[0].isCorrect],
      r2: [quizAnswers[1].isCorrect],
      r3: [quizAnswers[2].isCorrect],

    })

    this.quizQuestionForm.valueChanges.subscribe(
      data => console.log(data),
    )
  }

  onCheckboxChange(index) {
    console.log(index)

    this.quizQuestionForm.controls.r1.setValue(false)
    this.quizQuestionForm.controls.r2.setValue(false)
    this.quizQuestionForm.controls.r3.setValue(false)

    this.quizQuestionForm.controls[index].setValue(true)
  }

  onImageUpload(evt) {
    // tslint:disable-next-line:no-string-literal
    this.quizQuestionForm.controls['imageUrl'].setValue(evt)
  }

  onClickSubmit() {

    const toSave = {
      ... this.quizQuestionForm.value,
      id: this.id,
    }
    this.dialogRef.close(toSave)
  }

  onClickDecline() {
    this.dialogRef.close()
  }

}

export interface AddQuestionModalData {
  question: QuizQuestion
}
