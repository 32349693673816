import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as fromAccount from './account.reducer'

export const selectAccountState = createFeatureSelector<fromAccount.AccountState>(
  fromAccount.accountFeatureKey,
)

export const selectUserId = createSelector(
  selectAccountState,
  ({ userId }) => userId,
)

export const selectAuthoritiesList = createSelector(
  selectAccountState,
  ({ authorities }) => authorities,
)

export const selectIsSuperModerator = createSelector(
  selectAccountState,
  ({ authorities }) => authorities.indexOf('ROLE_SUPER_MODERATOR'),
)

export const selectIsHeadModerator = createSelector(
  selectAccountState,
  ({ authorities }) => authorities.indexOf('ROLE_HEAD_MODERATOR'),
)

export const selectIsHeadOrSuperModerator = createSelector(
  selectAccountState,
  ({ authorities }) => authorities.includes('ROLE_SUPER_MODERATOR') || authorities.includes('ROLE_HEAD_MODERATOR'),
)

export const selectUser = createSelector(
  selectAccountState,
  ({ user }) => user,
)