import { Component, OnInit } from '@angular/core'
import { AccountService } from 'src/app/auth/account.service'
import { AppConstants } from 'src/app/app.constants'
import { Router } from '@angular/router'
import { LoginService } from 'src/app/auth/login.service'

@Component({
  selector: 'app-cmsnav-layout',
  templateUrl: './cmsnav-layout.component.html',
  styleUrls: ['./cmsnav-layout.component.scss'],
})
export class CmsnavLayoutComponent implements OnInit {

  baseUrlImage: string = AppConstants.BASE_IMAGE_URL
  public hasRoleSuperModerator: boolean;
  public firstName: string;
  constructor(
    public accountService: AccountService,
    public router: Router,
    private loginService: LoginService,
  ) { }

  ngOnInit() {
    this.hasRoleSuperModerator = this.accountService.hasRoleSuperModerator();
    this.firstName = this.accountService.getFirstName();
  }

  checkRoute() {
    return !this.router.url.includes('cms-nav/tournaments/new')
  }

  onClickLogout(): void {
    console.log('CmsnavLayoutComponent onClickLogout()')

    this.loginService.logout()
  }

}
