import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http'
import { AppConstants } from 'src/app/app.constants'

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor() {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!request || !request.url || (/^http/.test(request.url) && !(AppConstants.SERVER_API_URL && request.url.startsWith(AppConstants.SERVER_API_URL)))) {
            return next.handle(request)
        }

        request = request.clone({
            setHeaders: {
                APP_VERSION: AppConstants.APP_VERSION,
            },
        })

        const token = localStorage.getItem('authenticationToken') || sessionStorage.getItem('authenticationToken');
        if (!!token) {
            request = request.clone({
                setHeaders: {
                    Authorization: 'Bearer ' + token,
                },
            })
        }
        return next.handle(request)
    }
}
