import { Component, OnInit, ViewEncapsulation } from '@angular/core'

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

// jQuery(document).ready(function($) {
// 	$('.open-adding-rooms-modal').click(function() {
// 		$('.adding-rooms-modal').addClass('active')
// 	})

// 	$('.adding-rooms-modal .close-modal').click(function() {
// 		$('.adding-rooms-modal').removeClass('active')
// 	})
// })
