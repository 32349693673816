import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatPaginatorIntl } from '@angular/material'
import { MatPaginatorModule } from '@angular/material/paginator'
import { RouterModule, Routes } from '@angular/router'
import { EffectsModule } from '@ngrx/effects'
import { StoreModule } from '@ngrx/store'
import { MatPaginatorIntlNewsList } from '../shared/components/ajax-paginator.provider'
import { NewsCreateComponent } from './components/news-create/news-create.component'
import { NewsListItemComponent } from './components/news-list-item/news-list-item.component'
import { NewsListComponent } from './components/news-list/news-list.component'
import { NewsFeatureEffects } from './store/news-feature.effects'
import * as fromNewsReducer from './store/news-feature.reducer'
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
// import { AngularEditorModule } from '../../app/shared/components/angular-editor/angular-editor.module'
import { AngularEditorModule } from '@kolkov/angular-editor'
// import { SharedModule } from '../shared/share.module'
import { ImageLoaderComponent } from '../shared/components/image-loader/image-loader.component'
// import { AngularFontAwesomeModule } from 'angular-font-awesome'
import { ImageLoaderModule } from '../shared/components/image-loader/image-loader.module';

const routes: Routes = [
  { path: '', component: NewsListComponent },
  { path: 'new', component: NewsCreateComponent },
  { path: ':id/edit', component: NewsCreateComponent },
]

@NgModule({
  declarations: [
    NewsListComponent,
    NewsListItemComponent,
    NewsCreateComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AngularEditorModule,
    ImageLoaderModule,
    StoreModule.forFeature(fromNewsReducer.newsFeatureFeatureKey, fromNewsReducer.reducer),
    EffectsModule.forFeature([NewsFeatureEffects]),
    RouterModule.forChild(routes),
    MatPaginatorModule,

  ],
  exports: [
    NewsListComponent,
    NewsListItemComponent,
  ],
  providers: [
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlNewsList },
  ],
})
export class NewsModule { }
