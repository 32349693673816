import { Component, OnInit, OnDestroy } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator'
import { Store } from '@ngrx/store'
import { Observable, Subject } from 'rxjs'
import { debounceTime, takeUntil } from 'rxjs/operators'
import { AppState } from 'src/app/reducers'
import { PaginatorState } from '../../../shared/model/paginatorState.model'
import { loadNews, setNewsPaginator, setSearchString } from '../../store/news-feature.actions'
import { selectNewsCount, selectNewsList, selectNewsPaginator } from '../../store/news-feature.selectors'

@Component({
  selector: 'app-news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.scss'],
})
export class NewsListComponent implements OnInit, OnDestroy {

  constructor(
    private store: Store<AppState>,
    private formBuilder: FormBuilder,
  ) { }

  newsList$
  newsListCount$
  newsPaginatorState$: Observable<PaginatorState>
  newsListSearchBar

  destroy$: Subject<boolean> = new Subject<boolean>()

  searchForm: FormGroup


  ngOnDestroy(): void {
    this.destroy$.next(true)
  }

  ngOnInit() {
    this.loadNews()
    this.newsList$ = this.store.select(selectNewsList)
    this.newsListCount$ = this.store.select(selectNewsCount)
    this.newsPaginatorState$ = this.store.select(selectNewsPaginator)
    this.searchForm = this.formBuilder.group({
      newsSearchBar: [''],
    })

    this.searchForm.valueChanges.pipe(
        debounceTime(500),
        takeUntil(this.destroy$),
      ).subscribe( ({ newsSearchBar }) =>
    this.store.dispatch(setSearchString( { newsListSearch: newsSearchBar} )))
  }

  loadNews() {
    this.store.dispatch(loadNews())
  }

  onPaginatorChange(evt: PageEvent) {
    const { pageSize, pageIndex } = evt

    this.store.dispatch(
      setNewsPaginator({
        state: {
          size: pageSize,
          page: pageIndex,
        },
      }),
    )

    // TODO: move this refresh to effects
    this.loadNews()
  }

}
