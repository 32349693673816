import { Component, Inject } from '@angular/core'
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar'
import { Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { AppState } from 'src/app/reducers'
import * as ChatboxActions from '../../../actions/chatbox.actions'
import { ChatboxTabs } from '../../../chatbox/models/ChatboxTabs.enum'
import { imageAvatarFallback } from '../../../utils/string.util'
import { markAsReadMessagesInfoByChatId, onModeratorDirectTournamentSetActiveId } from '../../../actions/chatbox.actions';

@Component({
  selector: 'app-ajax-snack',
  templateUrl: './ajax-snack.component.html',
  styleUrls: ['./ajax-snack.component.scss'],
})
export class AjaxSnackComponent {

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: AjaxSnackData,
    public snackBarRef: MatSnackBarRef<AjaxSnackComponent>,
    private router: Router,
    private store: Store<AppState>,
  ) { }

  imageAvatarFallback = imageAvatarFallback

  onAction() {

    if (this.data.actionData) {
      const { tournamentId, chatId, chatType } = this.data.actionData

      if (chatType === ChatboxTabs.TM) {
        this.router.navigate(['/dashboard/tournament', tournamentId, 'timeline']).then( () => {
          this.store.dispatch(ChatboxActions.setOpen( { open: true}))
          this.store.dispatch(ChatboxActions.setActiveTab( { activeTab: chatType }))
          this.store.dispatch(ChatboxActions.onModeratorDirectTournamentSetActiveId({ activeId: chatId }))
          this.store.dispatch(ChatboxActions.markAsReadMessagesInfoByChatId({ chatId }))
        })
      }

      if (chatType === ChatboxTabs.Reports ) {
        this.router.navigate(['/dashboard/tournament', tournamentId, 'timeline']).then( () => {
          this.store.dispatch(ChatboxActions.setOpen({ open: true }))
          this.store.dispatch(ChatboxActions.setActiveTab({ activeTab: chatType }))

          this.store.dispatch(ChatboxActions.reportsSetActiveId({ chatId }))
          this.store.dispatch(ChatboxActions.markAsReadSupportByChatId({ chatId }))
        })
      }

      if( chatType === ChatboxTabs.Direct) {
        this.router.navigate(['/dashboard/tournament', tournamentId, 'timeline']).then( () => {
          this.store.dispatch(ChatboxActions.setOpen({ open: true }))
          this.store.dispatch(ChatboxActions.setActiveTab({ activeTab: chatType }))


          this.store.dispatch(onModeratorDirectTournamentSetActiveId({ activeId: chatId }))
          this.store.dispatch(markAsReadMessagesInfoByChatId({ chatId }))
        })
      }
    }
  }

  onClose() {
    this.snackBarRef.dismiss()
  }
}

export interface AjaxSnackData {
  imgUrl: string
  name: string
  text: string
  tournamentName?: string
  actionData?: {
    tournamentId: string
    chatId: string
    // tslint:disable-next-line:trailing-comma
    chatType: ChatboxTabs
  }
}
