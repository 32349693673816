import { Component, OnInit, Input } from '@angular/core'
import { imageAvatarFallback } from '../../../utils/string.util'

@Component({
  selector: 'app-moderator-direct-message-item',
  templateUrl: './moderator-direct-message-item.component.html',
  styleUrls: ['./moderator-direct-message-item.component.scss'],
})
export class ModeratorDirectMessageItemComponent implements OnInit {

  constructor() { }

  @Input()
  message

  avatarLinkHandler(url) {
    return imageAvatarFallback(url)
  }

  ngOnInit() {

  }

}
