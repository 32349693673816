import { HttpClient, HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { AppConstants } from '../../app.constants'
import { Faq } from '../models/faq.model'

@Injectable({
  providedIn: 'root',
})

export class FaqHttpService {

  fetchFaqs(page, size, search? ): Observable<HttpResponse<Faq[]>> {

    if ( search ) {
      return this.http.get<Faq[]>(`${AppConstants.SERVER_API_URL}/api/faqs?page=${page}&size=${size}&sort=id,desc`, { observe: 'response' })
    } else {
      return this.http.get<Faq[]>(`${AppConstants.SERVER_API_URL}/api/faqs?page=${page}&size=${size}&sort=id,desc`, { observe: 'response' })
    }
  }

  createFaq(dto) {
    return this.http.post(`${AppConstants.SERVER_API_URL}/api/faqs`, dto, { observe: 'response' })
  }

  editFaq({ faq }) {
    return this.http.put(`${AppConstants.SERVER_API_URL}/api/faqs`, faq, { observe: 'response' })
  }

  deleteFaq( { faqId } ) {
    return this.http.delete(`${AppConstants.SERVER_API_URL}/api/faqs/${faqId}`, { observe: 'body'})
  }

  fetchById( id: string ) {
    return this.http.get(`${AppConstants.SERVER_API_URL}/api/faqs/${id}`, { observe: 'body'})
  }

  constructor(
    private http: HttpClient,
  ) { }
}
