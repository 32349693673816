import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as fromFaqFeature from './faq-feature.reducer'
import { Faq } from '../models/faq.model'

export const selectFaqFeatureState = createFeatureSelector<fromFaqFeature.FaqState>(
  fromFaqFeature.faqFeatureFeatureKey,
)

export const selectFaqList = createSelector(
  selectFaqFeatureState,
  // tslint:disable-next-line
  ( { faqList } ) => Object.values(faqList).sort((a: Faq, b: Faq) => parseInt(b.id) - parseInt(a.id)),
)

export const selectFaqCount = createSelector(
  selectFaqFeatureState,
  ( { faqListLength }) => faqListLength,
)

export const selectFaqPaginator = createSelector(
  selectFaqFeatureState,
  ( { faqPaginator } ) => faqPaginator,
)
