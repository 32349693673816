import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { BehaviorSubject } from 'rxjs'
import { AppState } from '../reducers'
import { dropAuthorities } from './account.actions'
import { AccountService } from './account.service'
import { JwtAuthService } from './jwt-auth.service'

@Injectable({ providedIn: 'root' })
export class LoginService {

    private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

    constructor(
        private jwtAuthService: JwtAuthService,
        private router: Router,
        private accountService: AccountService,
        private store: Store<AppState>,
    ) { }

    get isLoggedIn() {
        return this.loggedIn.asObservable()
    }

    login(payload, callback?) {

        console.log('LoginService payload:::', payload)

        const cb = callback || function() { }
        return new Promise((resolve, reject) => {
            this.jwtAuthService.login(payload).subscribe(
                result => {
                    console.log('data:::', result)
                    if(result) {
                        switch (result.status) {
                            case 200:
                            //    this.router.navigate(['/dashboard/home-user'])
                                this.loggedIn.next(true)
                                break
                            case 400:
                                resolve(result)
                                break
                            default:
                                break
                        }
                    }


                },
                err => {
                    console.log('LoginService login....err ...:::', err)

                    // this.logout();
                    reject(err)
                    // return cb(err);
                    return err
                },
            )
        })
    }

    loginWithToken(jwt, rememberMe) {
        return this.jwtAuthService.loginWithToken(jwt, rememberMe)
    }

    logout() {
        this.store.dispatch(dropAuthorities())
        this.jwtAuthService.logout()
        this.accountService.clearIdentity()
        this.loggedIn.next(false)
        this.router.navigate(['/login'])
    }

    clearToken() {
        this.jwtAuthService.logout()
    }
}
