import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { of } from 'rxjs'
import { catchError, concatMap, map, withLatestFrom } from 'rxjs/operators'
import { TournamentService } from '../moderators/tournament.service'
import { AppState } from '../reducers/index'
import { TourMatchService } from '../services/tour-match.service'
import { TournamentsService } from '../services/tournament.service'
import * as TimelineFeatureActions from './timeline-feature.actions'
import { selectTournamentId } from './timeline-feature.selectors'
import { TimelineService } from './timeline.service'
import { selectIsHeadOrSuperModerator } from '../auth/account.selectors';



@Injectable()
export class TimelineFeatureEffects {

  clickStartEff$ = createEffect(() => {

    return this.actions$.pipe(
      ofType(TimelineFeatureActions.clickStartAction),
      withLatestFrom(this.store.select(selectTournamentId)),

      concatMap(([action, tournamentId]) => {
        const { userId, matchId } = action

        return this.timelineService.clickStart({ userId, matchId, tournamentId }).pipe(
          map(data => TimelineFeatureActions.clickStartActionSuccess({ data })),
          catchError(error => of(TimelineFeatureActions.clickStartActionFailure({ error }))))
      }),
    )
  })

  clickFinishEff$ = createEffect(() => {

    return this.actions$.pipe(
      ofType(TimelineFeatureActions.clickFinishAction),
      withLatestFrom(this.store.select(selectTournamentId)),

      concatMap(([action, tournamentId]) => {
        const { userId, matchId } = action

        return this.timelineService.clickFinish({ userId, matchId, tournamentId }).pipe(
          map(data => TimelineFeatureActions.clickFinishActionSuccess({ data })),
          catchError(error => of(TimelineFeatureActions.clickFinishActionFailure({ error }))))
      }),
    )
  })

  fetchNotificationsEffect$ = createEffect(() => {

    return this.actions$.pipe(
      ofType(TimelineFeatureActions.fetchNotifications),

      withLatestFrom(this.store.select(selectTournamentId)),

      concatMap(([action, tournamentId]) =>
        this.timelineService.fetchNotifications(tournamentId)
          .pipe(
            map(data => TimelineFeatureActions.fetchNotificationsSuccess({ data })),
            catchError(error => of(TimelineFeatureActions.fetchNotificationsFailure({ error }))),
          ),
      ),
    )
  })
  fetchParticipantsEffect$ = createEffect(() => {

    return this.actions$.pipe(
      ofType(TimelineFeatureActions.fetchParticipants),
      concatMap(action =>
        this.tournamentsService.getTouramentParticipants(action.tournamentId, undefined, undefined, 'ALL')
          .pipe(
            map(data => TimelineFeatureActions.fetchParticipantsSuccess({ data: data.body })),
            catchError(error => of(TimelineFeatureActions.fetchParticipantsFailure({ error }))),
          ),
      ),
    )
  })


  cancelMatchEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TimelineFeatureActions.cancelMatch),
      concatMap(({ matchId }) => {
        return this.tourMatchService.cancelTourMatch(matchId).pipe(
          map(data => TimelineFeatureActions.cancelMatchSuccess({ data: data.body })),
          catchError(error => of(TimelineFeatureActions.cancelMatchFailure({ error }))),
        )
      }),
    )
  })
  resetMatchEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TimelineFeatureActions.resetMatch),
      concatMap(({ matchId }) => {
        return this.tourMatchService.restartTourMatch(matchId).pipe(
          map(data => TimelineFeatureActions.resetMatchSuccess({ data: data.body })),
          catchError(error => of(TimelineFeatureActions.resetMatchFailure({ error }))),
        )
      }),
    )
  })


  tournamentIdSet$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TimelineFeatureActions.setTournamentId),

      concatMap(({ tournamentId }) => of(TimelineFeatureActions.loadTourData({ tournamentId })),
      ),
    )
  })

  tournamentIdSet1$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TimelineFeatureActions.setTournamentId),

      concatMap(({ tournamentId }) => of(TimelineFeatureActions.loadStages({ tournamentId })),
      ),
    )
  })


  loadTourData$ = createEffect(() => {
    return this.actions$.pipe(

      ofType(TimelineFeatureActions.loadTourData),
      concatMap(({ tournamentId }) => {

        /** An EMPTY observable only emits completion. Replace with your own observable API request */

        return this.tourService.getTournamentDetail(tournamentId).pipe(
          map(data => TimelineFeatureActions.loadTourDataSuccess({ data: data.body })),
          catchError(error => of(TimelineFeatureActions.loadTourDataFailure({ error }))))
      }),
    )
  })

  loadStages$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TimelineFeatureActions.loadStages),
      concatMap(({ tournamentId }) => {

        return this.tourService.getStagesByTour(tournamentId).pipe(
          map(data => TimelineFeatureActions.loadStagesSuccess({ data })),
          catchError(error => of(TimelineFeatureActions.loadStagesFailure({ error }))),

        )

      }),
    )
  })

  loadRooms = createEffect(() => {
    return this.actions$.pipe(
      ofType(TimelineFeatureActions.loadRooms),
      withLatestFrom(this.store.select(selectIsHeadOrSuperModerator)),
      concatMap(([action, isHeadOrSuperModerator]) => {

        const { stages, tournamentId } = action

        return this.tourService.getRoomsByStages(stages, tournamentId, true, isHeadOrSuperModerator).pipe(
          map(data => TimelineFeatureActions.loadRoomsSuccess({ data })),
          catchError(error => of(TimelineFeatureActions.loadRoomsFailure({ error }))),
        )
      }),

    )
  })

  constructor(
    private actions$: Actions,
    private tourService: TournamentService,
    private tournamentsService: TournamentsService,
    private tourMatchService: TourMatchService,
    private timelineService: TimelineService,
    private store: Store<AppState>,
  ) { }

}
