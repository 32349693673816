import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { AppConstants } from 'src/app/app.constants'
import { NewsItemCreateDTO } from '../models/news.model'

@Injectable({
  providedIn: 'root',
})
export class NewsHttpService {

  fetchNews( page = 0, size = 0, name? ) {
    return name
    ? this.http.get(`${AppConstants.SERVER_API_URL}/api/news/by-name?name=book&page=${page}&size=${size}&sort=id,desc`,
        { observe: 'response'},
      )
    : this.http.get(`${AppConstants.SERVER_API_URL}/api/news?page=${page}&size=${size}&sort=id,desc`, { observe: 'response' })
  }

  getNewsById(id: string) {
    return this.http.get(`${AppConstants.SERVER_API_URL}/api/news/${id}`, { observe: 'body' })
  }

  createNews(dto: NewsItemCreateDTO) {
    return this.http.post(`${AppConstants.SERVER_API_URL}/api/news`, dto, { observe: 'body' })
  }

  editNews({ newsItem }) {
    return this.http.put(`${AppConstants.SERVER_API_URL}/api/news`, newsItem, { observe: 'response' })
  }

  deleteNews(id: string) {
    return this.http.delete(`${AppConstants.SERVER_API_URL}/api/news/${id}`, { observe: 'body' })
  }

  constructor(
    private http: HttpClient,
  ) { }
}
