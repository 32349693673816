import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { EffectsModule } from '@ngrx/effects'
import { StoreModule } from '@ngrx/store'
import { TournamentDataEffects } from './store/tournament-data.effects'
import * as fromTournamentDataReducer from './store/tournament-data.reducer'



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromTournamentDataReducer.tournamentDataFeatureKey,
      fromTournamentDataReducer.reducer,
    ),
    EffectsModule.forFeature([TournamentDataEffects]),
  ],
})
export class TournamentDataModule { }
