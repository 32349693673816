import { Action, createReducer, on } from '@ngrx/store'
import * as NewsFeatureActions from './news-feature.actions'
import { PaginatorState } from '../../shared/model/paginatorState.model'

export const newsFeatureFeatureKey = 'newsFeature'

export interface NewsState {

  newsList: {}
  newsLength: number

  newsListSearch: string,
  newsPaginator: PaginatorState,

}

export const initialState: NewsState = {

  newsList: {},
  newsLength: undefined,

  newsListSearch: undefined,
  newsPaginator: {
    size: 5,
    page: 0,
    opts: [5, 10, 15],
  },
}

const newsFeatureReducer = createReducer(
  initialState,

  on(NewsFeatureActions.setSearchString, ( state, { newsListSearch} ) => ({ ...state, newsListSearch }) ),

  on(NewsFeatureActions.setNewsPaginator, ( state, { state: { size, page } }) => {
    return {
      ...state,
      newsPaginator: {
        ...state.newsPaginator, size, page,
      },
    }
  }),

  on(NewsFeatureActions.loadNewsSuccess, ( state, { data, newsLength }) => {

    const newsList = {}
    data.map(faq => newsList[faq.id] = faq)

    return { ...state, newsList, newsLength }
  }),

)

export function reducer(state: NewsState | undefined, action: Action) {
  return newsFeatureReducer(state, action)
}
