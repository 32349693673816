import { createFeatureSelector, createSelector } from '@ngrx/store'
import { UnreadChatType } from '../models/UnreadChatType.enum'
import * as fromUnreadFeature from './unread-feature.reducer'
import * as fromChatbox from '../../reducers/chatbox.reducer'

export const selectUnreadFeatureState = createFeatureSelector<fromUnreadFeature.UnreadState>(
  fromChatbox.chatboxFeatureKey,
)


export const selectUnreadGlobalChatCount = createSelector(
  selectUnreadFeatureState,
  ({ unreadData }) => {

    if ( !unreadData[UnreadChatType.GlobalChatMessage] ) {
      return {
        totalCount: 0,
        chatsCount: 0,
        byChats: {},
      }
    }

    const data = unreadData[UnreadChatType.GlobalChatMessage]
    const chatIdsArray = Object.keys(data)

    return {
      chatsCount: chatIdsArray.length,
      totalCount: chatIdsArray.map(chatId => data[chatId].length ).reduce(summ),
      byChats: chatIdsArray.map( chatId => ( { [chatId]: data[chatId].length }) ),

    }
  },
)

export const selectUnreadGlobalData = createSelector(
  selectUnreadFeatureState,
  ({ unreadData }) => unreadData[UnreadChatType.GlobalChatMessage] ? unreadData[UnreadChatType.GlobalChatMessage] : {},
)

export const selectUnreadGlobalCountById = createSelector(
  selectUnreadFeatureState,
  ( { unreadData }, { chatId } ) => {

    if (!unreadData[UnreadChatType.GlobalChatMessage]) {
      return 0
    }

    if (unreadData[UnreadChatType.GlobalChatMessage][ chatId ] ) {
      return Object.keys(unreadData[UnreadChatType.GlobalChatMessage][ chatId ]).length
    }

    return 0

  },
)

export const selectModeratorTournamentChatCount = createSelector(
  selectUnreadFeatureState,
  ({ unreadData }) => {

    if (!unreadData[UnreadChatType.ModeratorTournamentMessage]) {
      return {
        totalCount: 0,
        chatsCount: 0,
        byChats: {},
      }
    }

    const data = unreadData[UnreadChatType.ModeratorTournamentMessage]
    const chatIdsArray = Object.keys(data)

    return {
      chatsCount: chatIdsArray.length,
      totalCount: chatIdsArray.map(chatId => data[chatId].length).reduce(summ),
      byChats: chatIdsArray.map(chatId => ({ [chatId]: data[chatId].length })),

    }
  },
)

const summ = (accumulator, currentValue) => accumulator + currentValue


