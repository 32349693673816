import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { LoginLayoutComponent } from './login-layout.component';
import { LoginComponent } from 'src/app/login/login.component';
import { AuthGuard } from 'src/app/auth/auth.guard';
import { JwtAuthService } from 'src/app/auth/jwt-auth.service';
import { LoginService } from 'src/app/auth/login.service';
import { AccountService } from 'src/app/auth/account.service';
import { CommonModule } from '@angular/common';
import { LoginHomeGuard } from 'src/app/auth/login-home.guard';
import { SharedModule } from 'src/app/shared/share.module';
import { Routes, RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from 'src/app/blocks/interceptor/auth.interceptor';


const routes: Routes = [
    {
        path: '',
        component: LoginLayoutComponent,
        children: [

            {
                path: '',
                component: LoginComponent
            }
        ]
    }

];
@NgModule({
    declarations: [
        LoginLayoutComponent,
        LoginComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        RouterModule.forChild(routes),
    ],
    exports: [
        LoginLayoutComponent,
        LoginComponent,
    ],
    providers: [AuthGuard, JwtAuthService, LoginService, AccountService, LoginHomeGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
          },
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class LoginLayoutModule { }