import { HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { of } from 'rxjs'
import { catchError, concatMap, map, withLatestFrom } from 'rxjs/operators'
import { Faq } from '../models/faq.model'
import { FaqHttpService } from '../services/faq-http.service'
import * as FaqFeatureActions from './faq-feature.actions'
import { AppState } from '../../reducers/index'
import { Store } from '@ngrx/store'
import { selectFaqPaginator } from './faq-feature.selectors'


@Injectable()
export class FaqFeatureEffects {

  onFaqListChanged$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        FaqFeatureActions.createFaqSuccess,
        FaqFeatureActions.deleteFaqSuccess,
        FaqFeatureActions.editFaqSuccess,
        ),
      concatMap(faq => of(FaqFeatureActions.loadFaqs()),
      ))
  })



  fetchFaqEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FaqFeatureActions.loadFaqs),
      withLatestFrom(this.store.select(selectFaqPaginator)),
      concatMap(([action, { page, size }]) => {

        return this.faqHttp.fetchFaqs(page, size).pipe(
          map((response: HttpResponse<Faq[]>) => {

            const faqListLength = +response.headers.get('X-Total-Count')
            const data = response.body

            return FaqFeatureActions.loadFaqsSuccess({ data, faqListLength })
          },
          ),
          catchError(error => of(FaqFeatureActions.loadFaqsFailure({ error }))),
        )
      }),
    )
  })

  createFaqEffectAPI$ = createEffect( () =>
    this.actions$.pipe(
      ofType(FaqFeatureActions.createFaq),
      concatMap(({ faq }) =>
        this.faqHttp.createFaq(faq).pipe(
          map(data => FaqFeatureActions.createFaqSuccess({ data })),
          catchError(error => of(FaqFeatureActions.createFaqFailure({ error }))))
      ),
    ),
  )

  editFaqEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FaqFeatureActions.editFaq),
      concatMap(({ faq }) =>
        this.faqHttp.editFaq({ faq }).pipe(
          map(data => FaqFeatureActions.editFaqSuccess({ data }) ),
          catchError(error => of(FaqFeatureActions.editFaqFailure({ error }))),
        ),
      ),
    )
  })

  deleteFaqEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FaqFeatureActions.deleteFaq),
      concatMap(({ faqId }) =>
        this.faqHttp.deleteFaq({ faqId }).pipe(
          map(data => FaqFeatureActions.deleteFaqSuccess({ data }),
          ),
          catchError(error => of(FaqFeatureActions.deleteFaqFailure({ error }))),
        ),
      ),
    )
  })


  constructor(
    private actions$: Actions,
    private faqHttp: FaqHttpService,
    private store: Store<AppState>,
  ) {
    console.log('Faq effects!')
  }


}
